<template>
  <div>
    <x-form-validator ref="refFormObserver">
      <x-form-feild
        label="名称"
        require
      >
        <b-form-input
          v-model="formData.modelMeta.name"
          :readonly="formType === $x.biz.FormType.VIEW"
          placeholder="请输入名称"
        />
      </x-form-feild>
      <x-form-feild
        label="类型"
        require
      >
        <v-select
          v-model="formData.modelMeta.type"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="typeList"
          label="name"
          placeholder="请选择类型"
          :reduce=" option => option.id"
          :disabled="formType === $x.biz.FormType.VIEW"
        >
          <template slot="no-options">
            {{ $t('noData') }}
          </template>
        </v-select>
      </x-form-feild>
      <x-form-feild
        v-if="formData.modelMeta.type === 'timer'"
        label="cron表达式"
        require
      >
        <b-form-input
          v-model="formData.modelMeta.cron"
          :readonly="formType === $x.biz.FormType.VIEW"
          placeholder="请输入cron表达式"
        />
      </x-form-feild>
      <x-form-feild label="SQL">
        <div class="codeEditBox">
          <editor :value.sync="formData.modelMeta.sql" lang="mysql">
          </editor>
        </div>
      </x-form-feild>
      <x-form-feild label="执行动作">
        <execute-action-form
          :form-type="formType"
          :value="formData.modelMeta.actions"
        />
      </x-form-feild>
      <x-form-feild label="失败执行动作"
                    class="mb-3">
        <execute-action-form
          :value="formData.modelMeta.whenErrorThen"
          :form-type="formType"
        />
      </x-form-feild>
    </x-form-validator>
    <div class="fixed-bottom d-flex">
      <b-button
        v-if="formType !== $x.biz.FormType.VIEW"
        class="flex-grow-1 ml-2 mr-2 mb-2"
        variant="primary"
        type="submit"
        @click.prevent="validationForm"
      >
        确定
      </b-button>
    </div>
  </div>
</template>
<script>
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import vSelect from 'vue-select'
import Editor from '@core/components/cx/code-editor/editor.vue'
import {
  BButton, BFormInput,
} from 'bootstrap-vue'
import {
  patch,
} from '@/api/data-center/forward'
import ExecuteActionForm from '../components/ExecuteActionForm.vue'

export default {
  components: {
    BButton,
    BFormInput,
    vSelect,
    ExecuteActionForm,
    Editor,
    XFormValidator,
    XFormFeild,
  },
  props: {
    formData: {
      modelMeta: {
        actions: [],
        whenErrorThen: [],
      },
    },
    formType: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      typeList: [{
        id: 'timer',
        name: '定时',
      }, {
        id: 'realTime',
        name: '实时',
      }],
    }
  },
  methods: {
    validationForm() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.formData.name = this.formData.modelMeta.name
          this.formData.modelType = 'sql_rule'
          this.formData.modelMeta = JSON.stringify(this.formData.modelMeta)
          this.formData.modelVersion = 0
          patch(this.formData).then(() => {
            this.$emit('editSuccess')
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
