import request from '@/auth/jwt/useJwt'

/*  运行数据列表  */
export function query(pageIndex, pageSize, params) {
  let requestUrl = `rule-engine/scene/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&sorts%5B0%5D.name=createTime&sorts%5B0%5D.order=desc`
  let index = 0
  if (params) {
    if (params.name) {
      requestUrl += `&terms[${index}].column=name&terms[${index}].value=${params.name}`
      index++
    }
    if (params.state) {
      requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
/* 通知类型 */
export function types() {
  return request.axiosIns({
    url: 'notifier/config/types',
    method: 'get',
  })
}
/* 查询设备列表 */
export function queryDevices(pageNo) {
  return request.axiosIns({
    url: `device-instance/_query?pageIndex=${pageNo}&pageSize=8`,
    method: 'get',
  })
}
/* 根据Id查询设备 */
export function getDeviceById(id) {
  return request.axiosIns({
    url: `device/instance/${id}/detail`,
    method: 'get',
  })
}
/* 新增编辑 */
export function patch(data) {
  return request.axiosIns({
    url: 'rule-engine/scene',
    method: 'PATCH',
    data,
  })
}

// 删除
export function remove(id) {
  return request.axiosIns({
    url: `rule-engine/scene/${id}`,
    method: 'DELETE',
  })
}
// 启动
export function start(id) {
  return request.axiosIns({
    url: `rule-engine/scene/${id}/_start`,
    method: 'post',
  })
}
// 停止
export function stop(id) {
  return request.axiosIns({
    url: `rule-engine/scene/${id}/_stop`,
    method: 'post',
  })
}
// 执行
export function execute(id) {
  return request.axiosIns({
    url: `rule-engine/scene/${id}/execute`,
    method: 'post',
  })
}
// 无分页查询场景
export function scenesNoPaging() {
  return request.axiosIns({
    url: 'rule-engine/scene/_query/no-paging',
    method: 'get',
  })
}
