import request from '@/auth/jwt/useJwt'

/*  运行数据列表  */
export function eventQuery(pageIndex, pageSize, params, id) {
  let requestUrl = `rule-engine/instance/${id}/events?pageIndex=${pageIndex}&pageSize=${pageSize}&pageIndex=0&sorts%5B0%5D.name=createTime&sorts%5B0%5D.order=desc`
  if (params) {
    if (params.createTime) {
      requestUrl += `&terms%5B0%5D.column=createTime$btw&terms%5B0%5D.value=${params.createTime[0]},${params.createTime[1]}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
/* 运行日志 */
export function logQuery(pageIndex, pageSize, params, id) {
  let requestUrl = `rule-engine/instance/${id}/logs?pageIndex=${pageIndex}&pageSize=${pageSize}&pageIndex=0&sorts%5B0%5D.name=createTime&sorts%5B0%5D.order=desc`
  if (params) {
    if (params.createTime) {
      requestUrl += `&terms%5B0%5D.column=createTime$btw&terms%5B0%5D.value=${params.createTime[0]},${params.createTime[1]}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
