import request from '@/auth/jwt/useJwt'
import { getToken } from '@/@core/auth/token'

/*  查询设备列表  */
export function query(pageIndex, pageSize, params, productId) {
  let URL = `device-instance/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&sorts[0].name=createTime&sorts[0].order=desc`
  let index = 0
  if (productId) {
    URL += `&terms[${index}].column=productId&terms[${index}].value=${productId}`
    index += 1
  }
  if (params) {
    if (params.id) {
      URL += `&terms[${index}].column=id&terms[${index}].value=${params.id}`
      index += 1
    }
    if (params.name) {
      URL += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index += 1
    }
    if (params.createTime) {
      URL += `&terms[${index}].column=createTime$BTW&terms[${index}].value=${params.createTime.join(',')}`
    }
    if (params.state) {
      URL += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
    }
  }
  URL = encodeURI(URL)
  return request.axiosIns({
    url: URL,
    method: 'get',
  })
}
/*  查询设备列表  */
export function queryNoPage(params) {
  let URL = 'device-instance/_query/no-paging?sorts[0].name=createTime&sorts[0].order=desc'
  let index = 0
  if (params) {
    if (params.id) {
      URL += `&terms[${index}].column=id&terms[${index}].value=${params.id}`
      index += 1
    }
    if (params.productId) {
      URL += `&terms[${index}].column=productId&terms[${index}].value=${params.productId}`
      index += 1
    }
    if (params.name) {
      URL += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index += 1
    }
    if (params.state) {
      URL += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
    }
  }
  URL = encodeURI(URL)
  return request.axiosIns({
    url: URL,
    method: 'get',
  })
}
/* 查询产品列表  */
export function queryProductList() {
  return request.axiosIns({
    url: 'device-product/_query/no-paging?paging=false',
    method: 'get',
  })
}
/* 新增设备信息  */
export function add(data) {
  return request.axiosIns({
    url: '/device/instance',
    method: 'post',
    data,
  })
}
/* 编辑设备信息 */
export function edit(data) {
  return request.axiosIns({
    url: '/device/instance',
    method: 'patch',
    data,
  })
}
/* 查询所属机构 */
export function queryOrgin() {
  return request.axiosIns({
    url: 'organization/_all',
    method: 'get',
  })
}
/* 设备启用 */
export function deploy(id) {
  return request.axiosIns({
    url: `device-instance/${id}/deploy`,
    method: 'post',
  })
}
/* 设备禁用 */
export function unDeploy(id) {
  return request.axiosIns({
    url: `device-instance/${id}/undeploy`,
    method: 'post',
  })
}
/* 断开设备 */
export function disconnect(id) {
  return request.axiosIns({
    url: `device-instance/${id}/disconnect`,
    method: 'post',
  })
}
/* 删除设备 */
export function remove(id) {
  return request.axiosIns({
    url: `device-instance/${id}`,
    method: 'DELETE',
  })
}
/*  查询设备信息  */
export function getById(id) {
  return request.axiosIns({
    url: `device/instance/${id}/detail`,
    method: 'GET',
  })
}
/*  获取设备的告警历史记录  */
export function getAlarmRecordByDeviceId(pageNo, id) {
  return request.axiosIns({
    url: `device/alarm/history/_query?pageSize=10&pageIndex=${pageNo}&sorts[0].name=alarmTime&sorts[0].order=desc&terms[0].column=deviceId&terms[0].value=${id}`,
    method: 'GET',
  })
}
/* 查询该产品下的各状态下的设备数量 */
export function queryStateCount(state, productId) {
  let URL = 'device-instance/_count'
  if (state !== undefined && state !== null) {
    URL = `device-instance/_count?terms[0].column=state&terms[0].value=${state}`
  }
  if (productId !== undefined && productId !== null) {
    URL = `device-instance/_count?terms[0].column=productId&terms[0].value=${productId}`
  }
  if (state !== undefined && productId !== undefined && state !== null && productId !== null) {
    URL = `device-instance/_count?terms[0].column=state&terms[0].value=${state}&terms[1].column=productId&terms[1].value=${productId}`
  }
  return request.axiosIns({
    url: URL,
    method: 'get',
  })
}
// 查询设备的日志
export function queryLogByDeviceId(pageNo, pageSize, deviceId, params) {
  let index = 0
  let URL = `device-instance/${deviceId}/logs?pageIndex=${pageNo}&pageSize=${pageSize}&sorts[0].name=createTime&sorts[0].order=desc`
  if (params) {
    if (params.type) {
      URL += `&terms[${index}].column=type&terms[${index}].value=${params.type}`
      index++
    }
    if (params.createTime) {
      URL += `&terms[${index}].column=createTime$BTW&terms[${index}].value=${params.createTime.join(',')}`
    }
  }
  return request.axiosIns({
    url: URL,
    method: 'get',
  })
}
// 查询设备运行数据
export function multi(deviceId, productId, size) {
  if (!size) {
    size = 15
  }
  return request.axiosIns({
    url: 'dashboard/_multi',
    method: 'POST',
    data: {
      dashboard: 'device',
      dimension: 'history',
      measurement: 'properties',
      object: `${productId}`,
      params: {
        deviceId: `${deviceId}`,
        history: size,
      },
    },
  })
}
/* 获得设备配置 */
export function getConfig(id) {
  return request.axiosIns({
    url: `device/instance/${id}/config-metadata`,
    method: 'GET',
  })
}
/* 编辑设备配置 */
export function editConfig(id, data) {
  return request.axiosIns({
    url: `/device/instance/${id}`,
    method: 'PUT',
    data,
  })
}
/* 修改设备物模型 */
export function editMetadata(id, data) {
  return request.axiosIns({
    url: `/device/instance/${id}/metadata`,
    method: 'PUT',
    data,
  })
}
/* 恢复默认配置 */
export function restoreConfig(id) {
  return request.axiosIns({
    url: `/device/instance/${id}/configuration/_reset`,
    method: 'PUT',
  })
}
/* 设置物模型属性 */
export function setPropertyValue(id, data) {
  return request.axiosIns({
    url: `/device/instance/${id}/property`,
    method: 'PUT',
    data,
  })
}
/* 获取物模型属性 */
export function getPropertyValue(id, property) {
  return request.axiosIns({
    url: `/device/standard/${id}/property/${property}`,
    method: 'GET',
  })
}
/* 获取物模型属性历史数据 */
export function getPropertyHistory(pageIndex, pageSize, id, property, time) {
  let URL = `/device-instance/${id}/properties/_query?sorts[0].name=timestamp&sorts[0].order=desc&pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=property&terms[0].value=${property}`
  if (time) {
    URL += `&terms[1].column=timestamp$BTW&terms[1].value=${time}`
  }
  return request.axiosIns({
    url: URL,
    method: 'GET',
  })
}
/* 获取事件次数 */
export function getEventCount(id, event) {
  return request.axiosIns({
    url: `/device/instance/${id}/event/${event}?format=true&pageSize=1`,
    method: 'GET',
  })
}
/* 获取事件历史记录 */
export function getEventHistory(pageIndex, pageSize, id, event, time) {
  let URL = `/device/instance/${id}/event/${event}?format=true&pageIndex=${pageIndex}&pageSize=${pageSize}`
  if (time) {
    URL += `&terms[1].column=timestamp$BTW&terms[1].value=${time}`
  }
  return request.axiosIns({
    url: URL,
    method: 'GET',
  })
}

/* 编辑设备标签 */
export function editTags(id, data) {
  return request.axiosIns({
    url: `/device/instance/${id}/tag`,
    method: 'PATCH',
    data,
  })
}

/* 删除设备标签 */
export function deleteTag(id, tid) {
  return request.axiosIns({
    url: `/device/instance/${id}/tag/${tid}`,
    method: 'DELETE',
  })
}

/* 调用功能 */
export function callFunction(deviceId, fid, data) {
  return request.axiosIns({
    url: `/device/invoked/${deviceId}/function/${fid}`,
    method: 'POST',
    data,
  })
}

// 重置设备物模型
export function resetThingsModel(id) {
  return request.axiosIns({
    url: `/device/instance/${id}/metadata`,
    method: 'DELETE',
  })
}
// 同步所有设备状态
export function syncState() {
  return request.axiosIns({
    url: `/device-instance/state/_sync/?sorts%5B0%5D.name=id&sorts%5B0%5D.order=desc&:X_Access_Token=${getToken()}`,
    method: 'GET',
  })
}
// 激活所有设备
export function activeAll() {
  return request.axiosIns({
    url: `/device-instance/deploy?sorts%5B0%5D.name=id&sorts%5B0%5D.order=desc&:X_Access_Token=${getToken()}`,
    method: 'GET',
  })
}
