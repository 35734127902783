import request from '@/auth/jwt/useJwt'

// 新增告警设置
export function patchAlarmConfig(data) {
  return request.axiosIns({
    url: `/device/alarm/${data.target}/${data.targetId}`,
    method: 'PATCH',
    data,
  })
}

/*  获取设备的告警历史记录  */
export function getAlarmRecordByDeviceId(pageNo, id) {
  return request.axiosIns({
    url: `device/alarm/history/_query?pageSize=10&pageIndex=${pageNo}&sorts[0].name=alarmTime&sorts[0].order=desc&terms[0].column=deviceId&terms[0].value=${id}`,
    method: 'GET',
  })
}
