<template>
  <div>
    <x-table
      title="规则实例"
      ref="table"
      :card="true"
      :options="options"
      @rowAdd="addHandle"
      @rowDelete="rowDelete"
      @rowStart="rowStart"
      @rowStop="rowStop"
      @rowRestart="rowStart"
      @search="searchHandle"
      @rowLog="rowLog">
      <template slot="row-edit" slot-scope="scope">
        <b-button
          v-ripple.400="$x.ripple.primary"
          class="btn-icon rounded-circle"
          variant="flat-primary"
          @click="toEdit(scope.row)">
          <feather-icon icon="EditIcon" />
        </b-button>
      </template>
    </x-table>
    <b-modal
      id="modal-form"
      ref="my-modal"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="保存"
      cancel-title="取消"
      centered
      size="lg"
      :visible="modalFlag"
      :title="modalTitle"
      @hidden="resetModal"
    >
      <log-form
       :visible="modalFlag"
       :id="modalId"
      />
    </b-modal>
    <b-modal
      ref="logModal"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      size="xl"
      :visible="logModalFlag"
      :title="modalTitle"
      @hidden="resetLogModal"
    >
      <log-tab
        :instance="instance"
      />
    </b-modal>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      shadow
      width="85%"
      :title="sideTitle"
      :visible="visibleFlag"
      @hidden="sideHide"
    >
      <alarm-rule-form
        v-if="sideType === 'device_alarm' && visibleFlag"
        :obj-id="formData.targetId"
        :is-product="formData.target === 'product'"
        :form-type="formType"
        :form-data="formData"
        @editSuccess="editSuccess()"
      />
      <scene-linkage-form
        v-else-if="sideType === 'rule-scene' && visibleFlag"
        :form-type="formType"
        :form-data="formData"
        @editSuccess="editSuccess(true)"
      />
      <forward-form
        v-else-if="sideType === 'sql_rule' && visibleFlag"
        :form-type="formType"
        :form-data="formData"
        @editSuccess="editSuccess"
      />
    </b-sidebar>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BButton, BModal, BSidebar,
} from 'bootstrap-vue'
import {
  query, add, remove, start, stop,
} from '@/api/data-center/instance'
import AlarmRuleForm from '@/views/device-center/device-manager/alarm/AlarmRuleForm.vue'
import LogForm from '../components/LogForm.vue'
import SceneLinkageForm from '../scene-linkage/sceneLinkageForm.vue'
import ForwardForm from '../forward/forwardForm.vue'
import logTab from './logTab.vue'

export default {
  components: {
    XTable,
    BButton,
    BModal,
    LogForm,
    BSidebar,
    AlarmRuleForm,
    SceneLinkageForm,
    ForwardForm,
    logTab,
  },
  data() {
    return {
      instance: undefined,
      // modal标题
      modalTitle: undefined,
      // modal显示隐藏
      modalFlag: false,
      // 日志modal隐藏
      logModalFlag: false,
      logModalTitle: undefined,
      formData: {},
      // modalId
      modalId: undefined,
      options: {
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          // actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}' },
          { action: 'edit', name: '编辑', icon: 'EditIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' },
          { action: 'start', name: '启动', icon: 'PlayIcon' },
          { action: 'stop', name: '停止', icon: 'PauseIcon' },
          { action: 'restart', name: '重启', icon: 'PauseCircleIcon' },
          { action: 'log', name: '日志', icon: 'FileTextIcon' },
        ],
        actionFilter: (action, row) => {
          if (row.state.value === 'stopped') {
            return action === 'view' || action === 'edit' || action === 'delete' || action === 'start' || action === 'restart' || action === 'log'
          }
          return action === 'view' || action === 'edit' || action === 'stop' || action === 'restart' || action === 'log'
        },
        columns: [{
          label: 'ID',
          labelShow: true,
          prop: 'id',
          editDisable: true,
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入D',
          },
        }, {
          label: '名称',
          labelShow: true,
          prop: 'name',
          editDisable: true,
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入证书名称',
          },
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          type: 'select',
          addShow: false,
          rowSelectDot: true,
          selectVariant: prop => {
            if (prop.value === 'stopped') {
              return 'text-danger'
            }
            return 'text-success'
          },
          dictData: [{ text: '已停止', value: 'stopped' }, { text: '已启动', value: 'started' }],
          getDictLabel: option => option.label,
          props: { label: 'text', value: 'value' },
          searchShow: true,
        }, {
          label: '规则类型',
          labelShow: true,
          prop: 'modelType',
          type: 'select',
          addShow: false,
          rowSelect: true,
          selectVariant: prop => {
            if (prop === 'node-red') {
              return 'light-warning'
            }
            if (prop === 'sql_rule') {
              return 'light-primary'
            }
            if (prop === 'device_alarm') {
              return 'light-danger'
            }
            return 'light-success'
          },
          dictData: [{ id: 'node-red', name: '规则编排' }, { id: 'sql_rule', name: '数据转发' }, { id: 'device_alarm', name: '设备告警' }, { id: 'rule-scene', name: '场景联动' }],
          getDictLabel: option => option.label,
          props: { label: 'name', value: 'id' },
          searchShow: true,
        }, {
          label: '说明',
          labelShow: true,
          prop: 'description',
          type: 'textarea',
          rows: 5,
          searchShow: false,
        },
        ],
      },
      // sideBar 标题
      sideTitle: '编辑实例',
      sideType: undefined,
      visibleFlag: false,
      formType: undefined,
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    addHandle(formData, done) {
      formData.modelType = 'node-red'
      add(formData).then(resp => {
        if (resp.data.status === 200) {
          window.open(`/ailinks/rule-editor/index.html#flow/${formData.id}`)
        }
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    rowLog(data) {
      this.logModalFlag = true
      this.logModalTitle = data.name + '日志'
      this.instance = data
    },
    // 启动  重启
    rowStart(data, done) {
      start(data.id).then(() => {
        done()
      })
    },
    // 停止
    rowStop(data, done) {
      stop(data.id).then(() => {
        done()
      })
    },
    // 编辑
    toEdit(data) {
      if (data.modelType === 'node-red') {
        window.open(`/ailinks/rule-editor/index.html#flow/${data.id}`)
        return
      }
      this.formType = this.$x.biz.FormType.EDIT
      if (data.modelType === 'device_alarm') {
        this.sideType = 'device_alarm'
        this.sideTitle = '设备告警'
        this.formData = this._.cloneDeep(JSON.parse(data.modelMeta))
      } else if (data.modelType === 'sql_rule') {
        this.sideType = 'sql_rule'
        this.sideTitle = '数据转发'
        let formDataObj = this._.cloneDeep(data)
        formDataObj.modelMeta = JSON.parse(formDataObj.modelMeta)
        this.formData = this._.cloneDeep(formDataObj)
      } else if (data.modelType === 'rule-scene') {
        this.sideType = 'rule-scene'
        this.sideTitle = '场景联动'
        this.formData = this._.cloneDeep(JSON.parse(data.modelMeta))
      }
      this.visibleFlag = true
    },
    resetModal() {
      this.modalFlag = false
    },
    resetLogModal() {
      this.logModalFlag = false
      this.logModalTitle = ''
    },
    editSuccess(show) {
      if (show) {
        this.$xtoast.success('编辑成功！')
      }
      this.$refs.slider.hide()
      this.formData = {}
      this.$refs.table.__searchHandle()
    },
    sideHide() {
      this.visibleFlag = false
      this.formData = {
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
</style>
