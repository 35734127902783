import request from '@/auth/jwt/useJwt'

/*  分页查询组件列表  */
export function query(pageIndex, pageSize, params) {
  let requestUrl = `rule-engine/instance/_query?&pageIndex=${pageIndex}&pageSize=${pageSize}&sorts%5B0%5D.name=createTime&sorts%5B0%5D.order=desc`
  let index = 0
  if (params) {
    if (params.name) {
      requestUrl += `&terms[${index}].column=name&terms[${index}].value=${params.name}`
      index++
    }
    if (params.state) {
      requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
      index++
    }
    if (params.modelType) {
      requestUrl += `&terms[${index}].column=modelType&terms[${index}].value=${params.modelType}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
/*  新增规则实例  */
export function add(data) {
  return request.axiosIns({
    url: 'rule-editor/flows/_create',
    method: 'post',
    data,
  })
}
/* 删除实例 */
export function remove(id) {
  return request.axiosIns({
    url: `rule-engine/instance/${id}`,
    method: 'DELETE',
  })
}
// 启动
export function start(id) {
  return request.axiosIns({
    url: `rule-engine/instance/${id}/_start`,
    method: 'POST',
  })
}
// 启动
export function stop(id) {
  return request.axiosIns({
    url: `rule-engine/instance/${id}/_stop`,
    method: 'POST',
  })
}

// 查询规则实例的运行日志
export function getLogs(id, pageIndex, pageSize, params) {
  let requestUrl = `rule-engine/instance/${id}/logs?pageSize=${pageSize}&pageIndex=${pageIndex}&sorts%5B0%5D.name=createTime&sorts%5B0%5D.order=desc`
  if (params) {
    if (params.createTime) {
      requestUrl += `&terms[0].column=timestamp$btw&terms[0].value=${params.createTime}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
// 查询规则实例的运行数据
export function getEvents(id, pageIndex, pageSize, params) {
  let requestUrl = `rule-engine/instance/${id}/events?pageSize=${pageSize}&pageIndex=${pageIndex}&sorts%5B0%5D.name=createTime&sorts%5B0%5D.order=desc`
  if (params) {
    if (params.createTime) {
      requestUrl += `&terms[0].column=timestamp$btw&terms[0].value=${params.createTime}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
