<template>
  <div>
    <x-form-repeater
      v-if="triggers"
      ref="repeater"
      :data.sync="triggers"
      no-actions
      no-animation
      :is-view="formType === $x.biz.FormType.VIEW"
      :item-add="add"
      class="ml-1"
      :is-first="true"
    >
      <template
        slot="form-repeater"
        slot-scope="{item, index}"
      >
        <div class="d-flex flex-column w-100 pb-50">
          <div class="d-flex flex-row align-items-center mb-50">
            <div class="x-indicator"></div>
            <span class="ml-50 mr-50">{{'触发器' + (index + 1) + '：'}}</span>
            <repeat-filter v-if="repeatFilter"
                           :value="repeatFilter"
                           :form-type="formType"
                           @enter="setRepeatFilter">
            </repeat-filter>
          </div>
          <div class="d-flex w-100 justify-content-between ">
            <b-row class="w-100 d-flex align-items-center">
              <b-col
                md="2"
                class="mb-50"
              >
                <v-select
                  v-model="item.trigger"
                  :options="$x.biz.RuleEngine.Trigger.Mode.AlarmDictData"
                  placeholder="请选择触发类型"
                  :reduce=" option => option.value"
                  :disabled="formType === $x.biz.FormType.VIEW"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="item.trigger === $x.biz.RuleEngine.Trigger.Mode.TIMER"
                md="3"
                class="d-flex align-items-center mb-50"
              >
                <b-form-input
                  v-model="item.cron"
                  :readonly="formType === $x.biz.FormType.VIEW"
                  placeholder="请输入cron表达式"
                />
                <span
                  v-tooltip.hover="cronTip"
                  class="el-icon-question ml-50 text-secondary text-lighten-5 font-medium-1 x-link x-text-normal"
                  @click="toCronGuide"></span>
              </b-col>
              <b-col
                v-if="item.trigger === $x.biz.RuleEngine.Trigger.Mode.DEVICE"
                md="2"
                class="mb-50"
              >
                <v-select
                  v-model="triggers[index].type"
                  :options="$x.biz.RuleEngine.Trigger.Type.AlarmDictData"
                  placeholder="请选择触发方式"
                  :reduce=" option => option.value"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="option => selectDeviceType(option, index)"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="item.trigger === $x.biz.RuleEngine.Trigger.Mode.DEVICE
                  && triggers[index].type && triggers[index].type !== $x.biz.DevState.ONLINE
                  && triggers[index].type !== $x.biz.DevState.OFFLINE"
                md="3"
                class="mb-50"
              >
                <v-select
                  v-model="triggers[index].modelId"
                  :options="triggers[index].typeValues"
                  label="name"
                  class="w-100"
                  placeholder="请选择触发项"
                  :get-option-label="option => `${option.name}（${option.id}）`"
                  :reduce="option => option.id"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="option => selectTriggerValue(triggers[index], option.id)"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="item.trigger === $x.biz.RuleEngine.Trigger.Mode.DEVICE
                  && triggers[index].type && triggers[index].type === $x.biz.RuleEngine.Trigger.Type.PROPERTIES"
                md="12"
                class="mb-50"
              >
                <x-form-repeater
                  :data.sync="triggers[index].filters"
                  :is-view="formType === $x.biz.FormType.VIEW"
                  class="mt-50"
                  add-text="新增过滤条件"
                  no-actions
                  no-animation
                  :is-first="true"
                  :itemAdd="() => addFilter(triggers[index])"
                >
                  <template
                    slot="form-repeater"
                    slot-scope="scope"
                  >
                    <b-row
                      v-if="triggers[index].filters[scope.index]"
                      class="w-100"
                    >
                      <b-col
                        md="2"
                      >
                        <b-form-input
                          v-if="scope.index === 0"
                          v-model="triggers[index].filters[scope.index].key"
                          placeholder="请选择过滤条件KEY"
                          readonly
                          disabled
                        />
                        <b-form-input
                          v-else
                          :value="triggers[index].filters[0].key"
                          placeholder="请选择过滤条件KEY"
                          readonly
                          disabled
                        />
                      </b-col>
                      <b-col
                        md="3"
                      >
                        <v-select
                          v-model="triggers[index].filters[scope.index].operator"
                          :options="$x.biz.RuleEngine.Operators"
                          label="label"
                          placeholder="请选择操作符"
                          :reduce=" option => option.value"
                          :disabled="formType === $x.biz.FormType.VIEW"
                        >
                          <template slot="no-options">
                            {{ $t('noData') }}
                          </template>
                        </v-select>
                      </b-col>
                      <b-col
                        md="3"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="triggers[index].filters[scope.index].value"
                            placeholder="请输入过滤条件值"
                            readonly
                            :disabled="formType === $x.biz.FormType.VIEW"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-primary"
                              @click="showFilterValueModal(index, scope.index)"
                            >
                              输入
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                      <b-col
                        md="3"
                      >
                        <b-button
                          v-if="formType !== $x.biz.FormType.VIEW"
                          v-ripple.400="$x.ripple.danger"
                          variant="flat-danger"
                          size="sm"
                          class="btn-light-danger"
                          pill
                          @click="removeItemFilters(index, scope.index)"
                        >
                          <span class="align-middle">删除</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </template>
                </x-form-repeater>
              </b-col>
            </b-row>
            <b-col md="2">
              <b-button
                v-if="formType !== $x.biz.FormType.VIEW"
                v-ripple.400="$x.ripple.danger"
                variant="flat-danger"
                size="sm"
                class="btn-light-danger"
                pill
                @click="removeItem(index)"
              >
                删除触发器
              </b-button>
            </b-col>
          </div>
          <!--          <b-row><b-col><hr></b-col></b-row>-->
        </div>
      </template>
    </x-form-repeater>
    <property-value-form
      v-if="showFilterValue"
      :show.sync="showFilterValue"
      :propertys="[triggers[editTriggerIndex].typeValue]"
      :values="editFilterValue"
      :is-require="false"
      title="编辑"
      @enter="editFilterValueDone"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import XFormRepeater from '@/@core/components/cx/table/XFormRepeater.vue'
import PropertyValueForm from '@/views/device-center/device-manager/things-model/PropertyValueForm.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import RepeatFilter from '../../../data-center/rule-engine/components/RepeatFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    XFormRepeater,
    vSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    PropertyValueForm,
    RepeatFilter,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    repeatFilter: {
      type: Object,
      default: () => {},
    },
    formType: {
      type: String,
      default: undefined,
    },
    obj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showFilterValue: false,
      editTriggerIndex: undefined,
      editFilterIndex: undefined,
      editFilterValue: undefined,
      triggers: this.value,
      // 选择的输出设备
      editIndex: undefined,
      selectDevice: undefined,
      cronTip: 'Cron表达式填写方式：\n您可以参考详细表达式！',
    }
  },
  created() {
    if (this.triggers.length > 0) {
      this.triggers.forEach((trigger, index) => {
        if (trigger.trigger) {
          // 初始化数据
          this.add(trigger)
        }
        if (trigger.trigger === this.$x.biz.RuleEngine.Trigger.Mode.DEVICE) {
          this.selectDevice = this.obj
          this.editIndex = index
          if (!trigger.filters) {
            trigger.filters = []
          }
          trigger.metadata = this.selectDevice.metadata || {}
          this.selectDeviceType({ value: trigger.type }, index, true)
          this.selectTriggerValue(trigger, this.triggers[index].modelId, true)
        }
      })
    }
  },
  methods: {
    setRepeatFilter(formData, done) {
      this.repeatFilter.enabled = formData.enabled
      if (this.repeatFilter.enabled) {
        this.repeatFilter.alarmFirst = formData.alarmFirst
        this.repeatFilter.time = formData.time
        this.repeatFilter.threshold = 1
      }
      done()
    },
    toCronGuide() {
      window.open('http://crontab.org/?spm=5176.11485173.0.0.61fc59afkRYQHs', '_blank')
    },
    rowSelected(rows) {
      if (rows && rows.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.selectDevice = rows[0]
      } else {
        this.selectDevice = undefined
      }
    },
    add(data) {
      if (data) {
        if (!data.modelId) {
          data.modelId = undefined
        }
        if (!data.filters) {
          data.filters = []
        }
        if (!data.typeValues) {
          data.typeValues = []
        }
        return data
      }
      // eslint-disable-next-line no-return-assign
      return {
        modelId: undefined,
        filters: [],
        typeValues: [],
        trigger: undefined,
      }
    },
    removeItem(index) {
      if (this.$refs.repeater) {
        this.$refs.repeater.removeItem(index)
      }
    },
    removeItemFilters(triggerIndex, index) {
      this.triggers[triggerIndex].filters.splice(index, 1)
    },
    clickChange(item) {
      this.tableRadio = item.id
    },
    // 选择触发类型
    selectDeviceType(option, index, keep) {
      if (!keep) {
        this.triggers[index].modelId = undefined
        this.triggers[index].filters = []
      }
      this.triggers[index].typeValues = []
      if (option.value === this.$x.biz.RuleEngine.Trigger.Type.PROPERTIES) {
        this.triggers[index].typeValues = this.triggers[0].metadata.properties
      } else if (option.value === this.$x.biz.RuleEngine.Trigger.Type.EVENT) {
        this.triggers[index].typeValues = this.triggers[0].metadata.events
      } else if (option.value === this.$x.biz.RuleEngine.Trigger.Type.FUNCTION) {
        this.triggers[index].typeValues = this.triggers[0].metadata.functions
      }
      if (this.$refs.repeater) {
        this.$refs.repeater.$forceUpdate()
      }
    },
    // 选择参数
    selectTriggerValue(trigger, value, keep) {
      // this.triggers[index].cron = undefined
      if (!keep) {
        trigger.filters = [{
          key: value,
        }]
      }
      for (let val of trigger.typeValues) {
        if (val.id === value) {
          trigger.typeValue = val
        }
      }
      if (this.$refs.repeater) {
        this.$refs.repeater.$forceUpdate()
      }
    },
    addFilter(trigger) {
      return { key: trigger.modelId }
    },
    showFilterValueModal(triggerIndex, filterIndex) {
      this.editTriggerIndex = triggerIndex
      this.editFilterIndex = filterIndex
      this.editFilterValue = {}
      this.editFilterValue[this.triggers[triggerIndex].filters[filterIndex].key] = this.triggers[triggerIndex].filters[filterIndex].value
        ? this.triggers[triggerIndex].filters[filterIndex].value : undefined
      this.showFilterValue = true
    },
    editFilterValueDone(formData, done) {
      this.$nextTick(() => {
        this.triggers[this.editTriggerIndex].filters[this.editFilterIndex].value = formData[this.triggers[this.editTriggerIndex].filters[this.editFilterIndex].key]
        if (this.$refs.repeater) {
          this.$refs.repeater.$forceUpdate()
        }
      })
      done()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
