<template>
  <div>
    <x-form-validator ref="refFormObserver">
      <b-row class="w-100">
        <b-col
          md="12"
          class="ml-1"
        >
          <el-collapse value="1" accordion>
            <el-collapse-item name="1">
              <template slot="title">
                <span class="x-text-bold font-medium-1">描述信息：</span>
              </template>
              <x-form-feild
                label="场景联动ID"
                require
              >
                <b-form-input
                  v-model="formData.id"
                  :readonly="formType !== $x.biz.FormType.ADD"
                  placeholder="请输入场景联动ID"
                />
              </x-form-feild>
              <x-form-feild
                label="场景名称"
                require
              >
                <b-form-input
                  v-model="formData.name"
                  :readonly="formType === $x.biz.FormType.VIEW"
                  placeholder="请输入场景名称"
                />
              </x-form-feild>
            </el-collapse-item>
          </el-collapse>
        </b-col>
      </b-row>
      <x-form-feild
        label="触发器"
        tip="触发器可为空，消息只要满足触发条件中任意一个即可触发。  "
      >
        <triggers-form
          :value="formData.triggers"
          :form-type="formType"
        />
      </x-form-feild>
      <x-form-feild
        label="执行动作"
        tip="满足条件后需要执行的动作"
        class="mb-3"
      >
        <execute-action-form
          :value="formData.actions"
          :form-type="formType"
        />
      </x-form-feild>
    </x-form-validator>
    <div class="fixed-bottom d-flex">
      <b-button
        v-if="formType !== $x.biz.FormType.VIEW"
        class="flex-grow-1 ml-2 mr-2 mb-2"
        variant="primary"
        type="submit"
        @click.prevent="validationForm"
      >
        确定
      </b-button>
    </div>
  </div>
</template>

<script>
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import {
  BButton, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import {
  patch,
} from '@/api/data-center/scene-linkage'
import ExecuteActionForm from '../components/ExecuteActionForm.vue'
import TriggersForm from '../components/TriggersForm.vue'

export default {
  components: {
    BButton,
    XFormValidator,
    XFormFeild,
    BFormInput,
    BRow,
    BCol,
    ExecuteActionForm,
    TriggersForm,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    formType: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
    }
  },
  created() {
    if (!this.formData.triggers) {
      this.formData.triggers = []
    }
    if (!this.formData.actions) {
      this.formData.actions = []
    }
  },
  methods: {
    validationForm() {
      if (this.formData !== null) {
        this.$refs.refFormObserver.validate().then(success => {
          if (success) {
            let data = this._.cloneDeep(this.formData)
            this.$delete(data, 'state')
            if (data.triggers.length > 0) {
              data.triggers.forEach(trigger => {
                if (trigger.trigger === this.$x.biz.RuleEngine.Trigger.Mode.DEVICE) {
                  this.$delete(trigger, 'scene')
                  this.$delete(trigger, 'typeValue')
                  this.$delete(trigger, 'typeValues')
                  this.$delete(trigger.device, 'metadata')
                  this.$delete(trigger.device, 'name')
                  this.$delete(trigger, 'cron')
                }
                if (trigger.trigger === this.$x.biz.RuleEngine.Trigger.Mode.SCENE) {
                  this.$delete(trigger, 'device')
                  this.$delete(trigger, 'cron')
                }
                if (trigger.trigger === this.$x.biz.RuleEngine.Trigger.Mode.TIMER) {
                  this.$delete(trigger, 'device')
                  this.$delete(trigger, 'scene')
                }
              })
            }
            if (data.actions.length > 0) {
              data.triggers.forEach(action => {
                this.$delete(action, 'trParams')
              })
            }
            patch(data).then(() => {
              this.$emit('editSuccess')
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
