<template>
  <b-tabs content-class="mt-1">
    <!-- This tabs content will always be mounted -->
    <b-tab title="运行数据">
      <div>
        <x-table
          title="运行数据列表"
          :card="true"
          :options="options"
          @search="searchEvent">
          <template slot="createTime" slot-scope="scope">
            <div>{{ new Date(scope.row.createTime).cxFormat('yyyy-MM-dd hh:mm:ss')  }} </div>
          </template>
        </x-table>
      </div>
    </b-tab>
    <b-tab
      title="运行日志"
      lazy
    >
      <div>
        <x-table
          title="运行日志列表"
          :card="true"
          :content="contentLog"
          :options="optionsLog"
          @search="searchLog">
          <template slot="createTime" slot-scope="scope">
            <div>{{ new Date(scope.row.createTime).cxFormat('yyyy-MM-dd hh:mm:ss')  }} </div>
          </template>
        </x-table>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import XTable from '@core/components/cx/table/XTable.vue'
import { eventQuery, logQuery } from '@/api/data-center/eventLogs'

export default {
  components: {
    BTabs,
    BTab,
    XTable,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: ['aaaa', 'bbbb', 'cccc'],
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        searchFold: true,
        lableVertical: true,
        // 导出按钮
        exportBtn: false,
        // 新增按钮
        addBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          { action: 'view', name: '查看', icon: 'EyeIcon' },
        ],
        actionFilter: action => action === 'view',
        columns: [{
          label: '时间',
          labelShow: true,
          prop: 'createTime',
          type: 'datetimerange',
          editDisable: true,
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入证书名称',
          },
        }, {
          label: '节点',
          labelShow: true,
          prop: 'nodeId',
          searchShow: false,
        }, {
          label: '内容',
          labelShow: true,
          prop: 'ruleData',
          searchShow: false,
        },
        ],
      },
      contentLog: ['aaaa', 'bbbb', 'cccc'],
      optionsLog: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        searchFold: true,
        lableVertical: true,
        // 导出按钮
        exportBtn: false,
        // 新增按钮
        addBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          { action: 'view', name: '查看', icon: 'EyeIcon' },
        ],
        actionFilter: action => action === 'view',
        columns: [{
          label: '时间',
          labelShow: true,
          prop: 'createTime',
          type: 'datetimerange',
          editDisable: true,
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入证书名称',
          },
        }, {
          label: '节点',
          labelShow: true,
          prop: 'nodeId',
          searchShow: false,
        }, {
          label: '日志级别',
          labelShow: true,
          prop: 'level',
          searchShow: false,
        }, {
          label: '内容',
          labelShow: true,
          prop: 'ruleData',
          searchShow: false,
        },
        ],
      },
    }
  },
  created() {
  },
  watch: {
  },
  methods: {
    searchEvent(page, params, done) {
      eventQuery(page.pageIndex - 1, page.pageSize, params, this.id).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    searchLog(page, params, done) {
      logQuery(page.pageIndex - 1, page.pageSize, params, this.id).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
  },
}
</script>
