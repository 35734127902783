<template>
  <div class="code-box">
    <editor
      disabled
      v-model="code"
      :lang="lang"
      :options="options"
      :theme="theme"
      @init="init"
    ></editor>
  </div>
</template>
<script>
import Editor from 'vue2-ace-editor'
import usAppConfig from '@core/app-config/useAppConfig'

export default {
  name: 'CodeEditor',
  components: {
    Editor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    lang: {
      type: String,
      default: 'javascript',
    },
    fontSize: {
      type: Number,
      default: 14,
    },
  },
  data() {
    return {
      code: this._.cloneDeep(this.value),
      options: {
        // 设置代码编辑器的样式
        enableBasicAutocompletion: true, // 启用基本自动完成
        enableSnippets: true, // 启用代码段
        enableLiveAutocompletion: true, // 启用实时自动完成
        tabSize: 2, // 标签大小
        fontSize: this.fontSize, // 设置字号
        showPrintMargin: false, // 去除编辑器里的竖线
      },
      // 系统主题
      themeConfig: usAppConfig().skin.value,
      // 脚本编辑器主题
      theme: 'pastel_on_dark',
    }
  },
  watch: {
    code(val) {
      this.$emit('update:value', val)
    },
    themeConfig(val) {
      // 切换脚本编辑器的主题
      if (val === 'dark') {
        require('brace/theme/pastel_on_dark')
        this.theme = 'pastel_on_dark'
      } else {
        require('brace/theme/tomorrow')
        this.theme = 'tomorrow'
      }
    },
  },
  created() {
    // 切换脚本编辑器的主题
    if (this.themeConfig === 'dark') {
      require('brace/theme/pastel_on_dark')
      this.theme = 'pastel_on_dark'
    } else {
      require('brace/theme/tomorrow')
      this.theme = 'tomorrow'
    }
  },
  methods: {
    init() {
      // require('brace/theme/solarized_dark')
      require('brace/ext/language_tools') // language extension prerequsite...
      require('brace/mode/yaml')
      require('brace/mode/json')
      require('brace/mode/less')
      require('brace/mode/mysql')
      require('brace/snippets/json')
      require('brace/mode/lua')
      require('brace/snippets/lua')
      require('brace/mode/javascript')
      require('brace/snippets/javascript')
    },
  },
}
</script>
<style lang="scss" scoped>
  .code-box {
    width: 100%;
    height: 20rem;
    border: 1px solid #dcdee2;
  }
  .dark-layout {
    .code-box {
      border: 1px solid #3b4253;
    }
  }
</style>
