import request from '@/auth/jwt/useJwt'

/*  运行数据列表  */
export function query(pageIndex, pageSize, params) {
  let requestUrl = 'rule-engine/instance/_query?pageSize=10&terms%5B0%5D.column=modelType&terms%5B0%5D.value=sql_rule&sorts%5B0%5D.name=createTime&sorts%5B0%5D.order=desc'
  let index = 1
  if (params) {
    if (params.name) {
      requestUrl += `&terms[${index}].column=name&terms[${index}].value=${params.name}`
      index++
    }
    if (params.state) {
      requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
/* 运行日志 */
export function logQuery(pageIndex, pageSize, params, id) {
  let requestUrl = `rule-engine/instance/${id}/logs?pageIndex=${pageIndex}&pageSize=${pageSize}&pageIndex=0&sorts%5B0%5D.name=createTime&sorts%5B0%5D.order=desc`
  if (params) {
    if (params.createTime) {
      requestUrl += `&terms%5B0%5D.column=createTime$btw&terms%5B0%5D.value=${params.createTime[0]},${params.createTime[1]}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
/* 通知类型 */
export function types() {
  return request.axiosIns({
    url: 'notifier/config/types',
    method: 'get',
  })
}
/* 根据通知类型获取该类型配置 */
export function configByTypeId(id) {
  return request.axiosIns({
    url: `notifier/config/_query/no-paging?terms%5B0%5D.column=type&terms%5B0%5D.value=${id}`,
    method: 'get',
  })
}
/* 根据配置获取该配置模板 */
export function templateByTypeIdAndConfigId(type, provicer) {
  return request.axiosIns({
    url: `notifier/template/_query/no-paging?terms%5B0%5D.column=type&terms%5B0%5D.value=${type}&terms%5B1%5D.column=provider&terms%5B1%5D.value=${provicer}`,
    method: 'get',
  })
}
/* 新增编辑 */
export function patch(data) {
  return request.axiosIns({
    url: 'rule-engine/instance',
    method: 'PATCH',
    data,
  })
}

// 删除
export function remove(id) {
  return request.axiosIns({
    url: `rule-engine/instance/${id}`,
    method: 'DELETE',
  })
}
// 启动
export function start(id) {
  return request.axiosIns({
    url: `rule-engine/instance/${id}/_start`,
    method: 'post',
  })
}
// 停止
export function stop(id) {
  return request.axiosIns({
    url: `rule-engine/instance/${id}/_stop`,
    method: 'post',
  })
}
