<template>
  <div>
    <div :class="formType === $x.biz.FormType.VIEW ? '' : 'x-link'" class="bg-light-primary d-flex align-items-center btn-sm " @click="showModal()">
      <span>触发机制：</span>
      <span v-if="value">{{ value.enabled ? '抑制触发' : '持续触发' }}</span>
      <span v-else>未设置</span>
      <span class="el-icon-s-tools font-medium-1 ml-50"></span>
    </div>
    <b-modal
      id="modalForm"
      ref="modalForm"
      :visible="showForm"
      title-class="x-text-bold"
      body-class="pl-4 pr-4"
      ok-title="保存"
      title="抑制机制"
      centered
      ok-only
      @hidden="hideFormView"
      @ok="enterDialogHandle">
      <x-form-validator ref="refFormObserver" v-if="showForm">
        <b-alert variant="primary" show class="w-100 ml-1 mr-1">
          <div class="alert-body d-flex flex-column">
            <div>● 持续触发：只要告警发生立即触发；</div>
            <div class="mt-50">● 抑制触发：在抑制时间内只触发一次告警，抑制重复告警；</div>
          </div>
        </b-alert>
        <x-form-feild label="触发策略">
          <b-row>
            <b-col md="12">
              <div class="d-flex ">
                <div :class="formData.enabled ? 'bg-light-secondary' : 'bg-light-primary'" class="btn x-link x-w-50 x-anim-fade-in"
                     @click="setTriggerStg(false)">持续触发</div>
                <div :class="formData.enabled ? 'bg-light-primary' : 'bg-light-secondary'" class="btn x-link x-w-50 x-anim-fade-in"
                     @click="setTriggerStg(true)">抑制触发</div>
              </div>
            </b-col>
          </b-row>
        </x-form-feild>
        <x-form-feild v-if="formData.enabled" label="抑制时间" require>
          <b-form-input
            v-model="formData.time"
            type="number"
            placeholder="请输入抑制时间，单位为秒，需大于0"
          />
        </x-form-feild>
        <x-form-feild v-if="formData.enabled" label="触发时机" require>
          <b-form-radio
            v-model="formData.alarmFirst"
            value="true"
            class="custom-control-primary"
            style="display: inline-block !important;"
          >
            立即触发
          </b-form-radio>
          <b-form-radio
            v-model="formData.alarmFirst"
            value="false"
            class="custom-control-primary ml-1"
            style="display: inline-block !important;"
          >
            触底触发
          </b-form-radio>
          <b-alert variant="primary" show class="mt-50">
            <div class="alert-body d-flex flex-column ">
              <div>● 立即触发：在抑制时间内只要发生告警立马触发，触发后抑制时间类相同告警不在触发；</div>
              <div class="mt-50">● 触底触发：在抑制时间内发生不触发告警，抑制时间结束时触发最后一次告警；</div>
            </div>
          </b-alert>
        </x-form-feild>
      </x-form-validator>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormRadio, BRow, BCol, BFormInput, BAlert,
} from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'

export default {
  components: {
    XFormValidator,
    XFormFeild,
    BFormRadio,
    BRow,
    BCol,
    BFormInput,
    BAlert,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    formType: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      showForm: false,
      formData: { enabled: false },
    }
  },
  watch: {
    value: {
      handler(val) {
        this.formData = val ? this._.cloneDeep(val) : { alarmFirst: true, enabled: false }
      },
      deep: true,
      immediate: true,
    },
    // formData: {
    //   handler(val) {
    //     this.$emit('update:value', val)
    //   },
    //   deep: true,
    // },
  },
  methods: {
    setTriggerStg(enabled) {
      this.formData.enabled = enabled
      this.$forceUpdate()
    },
    showModal() {
      if (this.formType === this.$x.biz.FormType.VIEW) {
        return
      }
      this.showForm = true
      this.formData = this.value ? this._.cloneDeep(this.value) : { alarmFirst: true, enabled: false }
    },
    hide() {
      if (this.$refs.refFormObserver) {
        this.$refs.refFormObserver.reset()
      }
      this.formData = {}
      this.showForm = false
      this.$emit('update:show', this.showForm)
    },
    enterDialogHandle(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.$emit('enter', this.formData, () => {
            this.hide()
          })
        }
      })
    },
    hideFormView() {
      this.hide()
      this.$emit('hide')
    },
  },
}
</script>

<style lang="scss">
  .bm-view {
    width: 100%;
    height: 20rem;
  }
</style>
