<template>
  <div>
    <x-form-repeater
      v-if="actionList"
      ref="actionRepeater"
      :data.sync="actionList"
      no-actions
      no-animation
      :is-view="formType === $x.biz.FormType.VIEW"
      :item-add="add"
      class="ml-1"
      :is-first="true"
    >
      <template
        slot="form-repeater"
        slot-scope="{item, index}"
      >
        <div class="d-flex flex-column w-100 pb-50">
          <div class="d-flex flex-row align-items-center mb-50">
            <div class="x-indicator" />
            <span class="ml-50">{{ '执行动作' + (index + 1) + '：' }}</span>
          </div>
          <div class="d-flex w-100 justify-content-between ">
            <b-row class="w-100">
              <b-col
                md="2"
                class="mb-50"
              >
                <v-select
                  v-model="item.executor"
                  :options="typeList"
                  label="name"
                  placeholder="请选择执行动作"
                  :reduce=" option => option.id"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="option => executorType(option, index)"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="item.executor === 'device-message-sender'"
                md="3"
                class="mb-50"
              >
                <b-input-group>
                  <b-form-input
                    placeholder="请选择设备"
                    readonly
                    :disabled="formType === $x.biz.FormType.VIEW"
                    :value="actionList[index].trParams && actionList[index].trParams.device ? actionList[index].trParams.device.name : undefined"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="showDeviceModal(index)"
                    >
                      选择
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col
                v-if="item.configuration.deviceId"
                md="3"
                class="mb-50"
              >
                <v-select
                  v-model="item.configuration.message.messageType"
                  :options="messageTypeList"
                  label="name"
                  placeholder="请选择属性/功能"
                  :reduce=" option => option.id"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="option => selectMessageType(option, index)"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="item.configuration.message && item.configuration.message.messageType === 'WRITE_PROPERTY' && item.configuration.deviceId"
                md="3"
                class="mb-50"
              >
                <v-select
                  v-model="item.trParams.propertId"
                  :options="item.trParams ? actionList[index].trParams.properties : []"
                  label="name"
                  placeholder="请选择属性"
                  :reduce=" option => option.id"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="option => selectProperty(option, index)"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="item.configuration.message && item.configuration.message.messageType === 'INVOKE_FUNCTION' && item.configuration.deviceId"
                md="3"
                class="mb-50"
              >
                <v-select
                  v-model="item.configuration.message.functionId"
                  :options="item.trParams ? actionList[index].trParams.functions : []"
                  label="name"
                  placeholder="请选择功能"
                  :reduce=" option => option.id"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="option => selectFunction(option, index)"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <div class="mb-50 w-100 d-flex"
                v-if="item.configuration.message && item.configuration.message.functionId && item.configuration.message.messageType === 'INVOKE_FUNCTION'">
                <b-col
                  v-for="(input, index1) in item.configuration.message.inputs"
                  :key="index1"
                  md="3"
                >
                  <v-select
                    v-if="item.trParams && item.trParams.funcs && item.trParams.funcs[input.name]
                      && (item.trParams.funcs[input.name].valueType === $x.datatype.Enum || item.trParams.funcs[input.name].valueType === $x.datatype.Boolean)"
                    v-model="input.value"
                    :options="item.trParams.funcs[input.name].elements"
                    label="name"
                    :placeholder="`请选择${input.name}`"
                    :reduce=" option => option.id"
                    :disabled="formType === $x.biz.FormType.VIEW"
                  >
                    <template slot="no-options">
                      {{ $t('noData') }}
                    </template>
                  </v-select>
                  <b-form-input
                    v-else
                    v-model="input.value"
                    :placeholder="`请输入${input.name}`"
                    :disabled="formType === $x.biz.FormType.VIEW"
                  />
                </b-col>
              </div>
              <b-col
                v-if="item.configuration.message && item.configuration.message.messageType === 'WRITE_PROPERTY' && item.trParams.propertId"
                md="3"
                class="mb-50"
              >
                <v-select
                  v-if="item.trParams && ( item.trParams.properType === $x.datatype.Enum || item.trParams.properType === $x.datatype.Boolean )"
                  v-model="item.trParams.propertValue"
                  :options="item.trParams ? item.trParams.propertiesValues : []"
                  label="name"
                  placeholder="请选择属性值"
                  :reduce=" option => option.id"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="option => selectPropertyValue(option, index)"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
                <b-form-input
                  v-else
                  v-model="item.trParams.propertValue"
                  placeholder="请输入属性值"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @blur="inputPropertyValue(index)"
                />
              </b-col>
              <b-col
                v-if="item.executor === 'notifier'"
                md="2"
                class="mb-50"
              >
                <v-select
                  v-model="item.configuration.notifyType"
                  :options="actionList[index].trParams.noticeTypes"
                  label="name"
                  placeholder="请选择通知类型"
                  :reduce=" option => option.id"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="option => selectNoticeType(option, index)"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="item.executor === 'notifier'"
                md="3"
                class="mb-50"
              >
                <v-select
                  v-model="item.configuration.notifierId"
                  :options="actionList[index].trParams.configList"
                  label="name"
                  placeholder="请选择通知配置"
                  :reduce=" option => option.id"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="option => selectConfig(option, index)"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="item.executor === 'notifier'"
                md="3"
                class="mb-50"
              >
                <v-select
                  v-model="item.configuration.templateId"
                  :options="actionList[index].trParams.templateList"
                  label="name"
                  placeholder="请选择通知模板"
                  :reduce=" option => option.id"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="selectNorifyTemplate"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
            </b-row>
            <b-col md="2">
              <b-button
                v-if="formType !== $x.biz.FormType.VIEW"
                v-ripple.400="$x.ripple.danger"
                variant="flat-danger"
                size="sm"
                class="btn-light-danger"
                pill
                @click="removeItem(index)"
              >
                <span class="align-middle">删除执行动作</span>
              </b-button>
            </b-col>
          </div>
        </div>
      </template>
    </x-form-repeater>
    <b-modal
      id="modal-bind"
      ref="my-modal"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      size="xl"
      title="选择设备"
      :visible.sync="modalFlag"
      @hidden="modalFlag = false"
      @ok="handleOk"
    >
      <x-table
        v-if="modalFlag"
        ref="deviceTable"
        :card="false"
        :options="options"
        :fixed="false"
        :selected-value="selectDevice ? [selectDevice] : []"
        @search="searchHandle"
        @rowSelected="rowSelected"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BInputGroup, BFormInput, BInputGroupAppend, BModal,
} from 'bootstrap-vue'
import XFormRepeater from '@/@core/components/cx/table/XFormRepeater.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import XTable from '@/@core/components/cx/table/XTable.vue'
import { query, getById } from '@/api/device-manage/device-list'
import { types, configByTypeId, templateByTypeIdAndConfigId } from '@/api/data-center/forward'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    vSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BModal,
    XTable,
    XFormRepeater,
  },
  directives: {
    Ripple,
  },
  props: {
    visibleFlag: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    formType: {
      type: String,
      default: undefined,
    },
    nextTodoId: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      actionList: this.value,
      modalFlag: false,
      // 选择的输出设备
      editIndex: undefined,
      selectDevice: undefined,
      typeList: [
        {
          id: 'notifier',
          name: '消息通知',
        }, {
          id: 'device-message-sender',
          name: '设备输出',
        },
      ],
      deviceList: [],
      // 选择类型  属性/功能
      messageTypeList: [
        {
          id: 'WRITE_PROPERTY',
          name: '设置属性',
        },
        {
          id: 'INVOKE_FUNCTION',
          name: '调用功能',
        },
      ],
      deviceTotal: 0,
      deviceCurrentPage: 1,
      devicePerPage: 8,
      options: {
        addBtn: false,
        exportBtn: false,
        printBtn: false,
        hideStyleSwitch: true,
        actions: [],
        rowSelectable: true,
        rowSingleSelectable: true,
        columns: [{
          label: '设备ID',
          prop: 'id',
          addShow: false,
          editShow: false,
          searchShow: true,
        }, {
          label: '设备名称',
          prop: 'name',
          addShow: false,
          editShow: false,
          searchShow: true,
        }, {
          label: '所属产品',
          prop: 'productId',
          linkUrl: '/device-center/products/view/{productId}',
          type: 'select',
          dictUrl: 'device-product/_query/no-paging?paging=false',
          props: { label: 'name', value: 'id' },
          getDictLabel: option => option.label + `（${option.value})`,
          searchShow: true,
        }, {
          label: '状态',
          prop: 'state',
          props: {
            label: 'text',
            value: 'value',
          },
          type: 'select',
          dictData: this.$x.biz.DevState.DictData,
          rowSelectDot: true,
          selectVariant: prop => {
            if (prop.value === 'notActive') {
              return 'text-warning'
            }
            if (prop.value === 'online') {
              return 'text-success'
            }
            return 'text-danger'
          },
          addShow: false,
          editShow: false,
          searchShow: true,
        }, {
          label: '创建时间',
          labelShow: true,
          prop: 'createTime',
          type: 'datetimerange',
          addShow: false,
          editShow: false,
          searchShow: true,
        }],
      },
    }
  },
  watch: {
    formType: {
      immediate: true,
      handler() {
        if (this.formType !== this.$x.biz.FormType.ADD) {
          this.actionListInit()
        }
      },
    },
  },
  methods: {
    searchHandle(page, params, done) {
      if (!params) {
        params = {}
      }
      query(page.pageIndex - 1, page.pageSize, params, params.productId).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowSelected(rows) {
      if (rows && rows.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.selectDevice = rows[0]
      } else {
        this.selectDevice = undefined
      }
    },
    add(data) {
      if (data) {
        data.trParams = {}
        data.configuration = {
          message: {},
        }
        return data
      }
      return {
        trParams: {},
        configuration: {
          message: {},
        },
      }
    },
    removeItem(index) {
      if (this.$refs.actionRepeater) {
        this.$refs.actionRepeater.removeItem(index)
      }
    },
    executorType(option, index) {
      if (option.id === 'notifier') {
        types().then(resp => {
          this.$nextTick(() => {
            if (this.$refs.actionRepeater) {
              this.actionList[index].trParams.noticeTypes = resp.data.result
              this.$refs.actionRepeater.$forceUpdate()
            }
          })
        })
      }
    },
    selectNoticeType(option, index) {
      this.actionList[index].configuration.notifierId = undefined
      this.actionList[index].trParams.configList = []
      this.actionList[index].configuration.templateId = undefined
      this.actionList[index].trParams.templateList = []
      configByTypeId(option.id).then(resp => {
        this.actionList[index].trParams.configList = resp.data.result
        this.$refs.actionRepeater.$forceUpdate()
      })
    },
    selectConfig(option, index) {
      this.actionList[index].configuration.templateId = undefined
      this.actionList[index].trParams.templateList = []
      templateByTypeIdAndConfigId(option.type, option.provider).then(resp => {
        this.actionList[index].trParams.templateList = resp.data.result
        this.$refs.actionRepeater.$forceUpdate()
      })
    },
    selectNorifyTemplate() {
      this.$refs.actionRepeater.$forceUpdate()
    },
    // 加载设备列表选择绑定
    showDeviceModal(index) {
      if (this.formType !== this.$x.biz.FormType.VIEW) {
        this.editIndex = index
        this.modalFlag = true
        this.selectDevice = this.actionList[this.editIndex].trParams.device
      }
    },
    handleOk() {
      if (this.selectDevice) {
        getById(this.selectDevice.id).then(resp => {
          const device = resp.data.result
          device.metadata = JSON.parse(device.metadata)
          this.actionList[this.editIndex].trParams.device = device
          this.actionList[this.editIndex].trParams.functions = device.metadata.functions
          this.actionList[this.editIndex].trParams.properties = device.metadata.properties
          this.actionList[this.editIndex].configuration.deviceId = device.id
          this.actionList[this.editIndex].configuration.productId = device.productId
          this.modalFlag = false
          this.editIndex = undefined
          this.$refs.actionRepeater.$forceUpdate()
        })
      }
    },
    resetModal() {
      this.modalFlag = false
    },
    clickChange(item) {
      this.tableRadio = item.id
    },
    // 选择属性
    selectProperty(option, index) {
      this.actionList[index].trParams.properType = option.valueType.type
      this.actionList[index].trParams.propertId = option.id
      if (option.valueType.type === this.$x.datatype.Enum || option.valueType.type === this.$x.datatype.Boolean) {
        if (option.valueType.type === this.$x.datatype.Boolean) {
          this.actionList[index].trParams.propertiesValues = [{
            id: option.valueType.falseValue,
            name: option.valueType.falseText,
          }, {
            id: option.valueType.trueValue,
            name: option.valueType.trueText,
          }]
        } else {
          let values = []
          option.valueType.elements.forEach(item => {
            if (item) {
              let obj = {
                id: item.value,
                name: item.text,
              }
              values.push(obj)
            }
          })
          this.actionList[index].trParams.propertiesValues = values
        }
      }
      this.$refs.actionRepeater.$forceUpdate()
    },
    // 选择属性值
    selectPropertyValue(option, index) {
      let obj = {}
      this.$set(obj, this.actionList[index].trParams.propertId, option.id)
      this.actionList[index].configuration.message.properties = obj
    },
    // 输入属性值
    inputPropertyValue(index) {
      let obj = {}
      this.$set(obj, this.actionList[index].trParams.propertId, this.actionList[index].trParams.propertValue)
      this.actionList[index].configuration.message.properties = obj
    },
    // 选择功能
    selectFunction(option, index, keep) {
      if (!keep) {
        this.actionList[index].configuration.message.functionId = option.id
        this.actionList[index].configuration.message.inputs = []
      }
      this.actionList[index].trParams.funcs = {}
      let inputs = []
      option.inputs.forEach(item => {
        inputs.push({
          name: item.id,
          value: undefined,
        })
        if (item.valueType) {
          if (item.valueType.type === this.$x.datatype.Boolean) {
            let obj = [{
              id: item.valueType.falseValue,
              name: item.valueType.falseText,
            }, {
              id: item.valueType.trueValue,
              name: item.valueType.trueText,
            }]
            this.actionList[index].trParams.funcs[item.id] = {
              elements: obj,
              name: item.name,
              valueType: item.valueType.type,
            }
          } else if (item.valueType.type === this.$x.datatype.Enum) {
            let arr = []
            item.valueType.elements.forEach(element => {
              let obj = {
                id: element.value,
                name: element.text,
              }
              arr.push(obj)
            })
            this.actionList[index].trParams.funcs[item.id] = {
              elements: arr,
              name: item.name,
              valueType: item.valueType.type,
            }
          }
        }
      })
      if (!keep) {
        this.actionList[index].configuration.message.inputs = inputs
      }
      this.$refs.actionRepeater.$forceUpdate()
    },
    // 选择类型
    selectMessageType(option, index) {
      if (option.id) {
        if (option.id === 'INVOKE_FUNCTION') {
          this.$delete(this.actionList[index].configuration.message, 'properties')
          this.actionList[index].trParams.propertId = undefined
          this.actionList[index].trParams.properType = undefined
        } else {
          this.$delete(this.actionList[index].configuration.message, 'inputs')
        }
        this.actionList[index].trParams.propertValue = undefined
        this.actionList[index].configuration.message.functionId = undefined
      }
    },
    // 编辑是加载数据
    actionListInit() {
      if (this.actionList.length > 0) {
        this.actionList.forEach((action, index) => {
          this.actionList[index].trParams = {}
          if (action.executor === 'device-message-sender') {
            getById(action.configuration.deviceId).then(resp => {
              let device = resp.data.result
              device.metadata = JSON.parse(device.metadata)
              this.actionList[index].trParams.device = device
              this.actionList[index].trParams.functions = device.metadata.functions
              this.actionList[index].trParams.properties = device.metadata.properties
              this.actionList[index].configuration.deviceId = device.id
              this.actionList[index].configuration.productId = device.productId
              if (action.configuration.message.messageType === 'WRITE_PROPERTY' && action.configuration.message.properties) {
                // 属性
                Object.keys(action.configuration.message.properties).forEach(prop => {
                  for (let property of device.metadata.properties) {
                    if (property.id === prop) {
                      this.selectproperty(property, index)
                      this.actionList[index].trParams.propertValue = action.configuration.message.properties[property.id]
                    }
                  }
                })
              } else if (action.configuration.message.messageType === 'INVOKE_FUNCTION') {
                for (let func of device.metadata.functions) {
                  if (func.id === action.configuration.message.functionId) {
                    this.selectFunction(func, index, true)
                  }
                }
              }
              this.$refs.actionRepeater.$forceUpdate()
            })
          } else if (action.executor === 'notifier') {
            types().then(resp => {
              this.actionList[index].trParams.noticeTypes = resp.data.result
              this.$refs.actionRepeater.$forceUpdate()
            })
            configByTypeId(action.configuration.notifyType).then(resp => {
              this.actionList[index].trParams.configList = resp.data.result
              for (let config of this.actionList[index].trParams.configList) {
                if (config.id === action.configuration.notifierId) {
                  templateByTypeIdAndConfigId(action.configuration.notifyType, config.provider).then(res => {
                    this.actionList[index].trParams.templateList = res.data.result
                    this.$refs.actionRepeater.$forceUpdate()
                  })
                  break
                }
              }
              this.$refs.actionRepeater.$forceUpdate()
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
