<template>
  <div>
    <b-tabs>
      <b-tab
        active
        title="运行数据">
        <x-table
          customXForm
          title="运行数据"
          ref="eventTable"
          :card="false"
          fixed
          :options="optionsEvent"
          @rowView="rowViewEvent"
          @search="searchEventHandle">
        </x-table>
      </b-tab>
      <b-tab
        title="运行日志"
      >
        <x-table
          customXForm
          title="运行日志"
          ref="logTable"
          :card="false"
          :options="optionsLog"
          @rowView="rowViewLog"
          @search="searchLogHandle">
          <template slot="level" slot-scope="scope">
            <div>
              <b-badge v-if="scope.row.level === 'info'" variant="primary">{{scope.row.level}}</b-badge>
              <b-badge v-if="scope.row.level === 'error'" variant="danger">{{scope.row.level}}</b-badge>
            </div>
          </template>
        </x-table>
      </b-tab>
    </b-tabs>
    <b-modal
      ref="logModal"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      size="lg"
      :visible="logModalFlag"
      title="详情信息"
      @hidden="resetLogModal"
    >
      <div class="p-1">
        <json-viewer :value="viewContent" :expand-depth=20 copyable sort boxed></json-viewer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BTabs, BTab, BModal, BBadge } from 'bootstrap-vue'
import XTable from '@core/components/cx/table/XTable.vue'
import {
  getLogs, getEvents,
} from '@/api/data-center/instance'

export default {
  components: {
    XTable,
    BTabs,
    BTab,
    BModal,
    BBadge,
  },
  props: {
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      viewContent: undefined,
      logModalFlag: undefined,
      logModalTitle: '详情信息',
      optionsEvent: {
        actions: [{ action: 'view', name: '详情', icon: 'EyeIcon' }],
        collapseExpandAll: true,
        formWidth: 'lg',
        addBtn: false,
        exportBtn: false,
        printBtn: false,
        hideStyleSwitch: true,
        columns: [{
          label: '时间',
          labelShow: true,
          prop: 'createTime',
          type: 'datetimerange',
          sortable: true,
          searchShow: true,
        }, {
          label: '节点',
          labelShow: true,
          prop: 'nodeId',
          searchShow: false,
        }, {
          label: '内容',
          labelShow: true,
          prop: 'ruleData',
          searchShow: false,
          rowShow: false,
        }],
      },
      optionsLog: {
        actions: [{ action: 'view', name: '详情', icon: 'EyeIcon' }],
        collapseExpandAll: true,
        formWidth: 'lg',
        addBtn: false,
        exportBtn: false,
        printBtn: false,
        hideStyleSwitch: true,
        columns: [{
          label: '时间',
          labelShow: true,
          prop: 'createTime',
          type: 'datetimerange',
          sortable: true,
          searchShow: true,
        }, {
          label: '节点',
          labelShow: true,
          prop: 'nodeId',
          searchShow: false,
        }, {
          label: '日志级别',
          labelShow: true,
          prop: 'level',
          searchShow: false,
        }, {
          label: '内容',
          labelShow: true,
          prop: 'message',
          searchShow: false,
          rowShow: false,
        }],
      },
    }
  },
  methods: {
    searchEventHandle(page, params, done) {
      getEvents(this.instance.id, page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    searchLogHandle(page, params, done) {
      getLogs(this.instance.id, page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowViewEvent(data) {
      this.viewContent = data.ruleData
      this.logModalFlag = true
    },
    rowViewLog(data) {
      this.viewContent = data.message
      this.logModalFlag = true
    },
    resetLogModal() {
      this.logModalFlag = false
    },
  },
}
</script>
