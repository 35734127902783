<template>
  <div style="height: inherit">
    <b-form
      ref="form"
      :style="{height: trHeight}"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >
      <b-row
        v-for="(item, index) in properties"
        :id="item.id"
        :key="item.id"
        :index="index"
        ref="row"
      >
        <b-col md="4">
          <b-form-group>
            <b-form-input
              v-model="item.property"
              placeholder="请输入属性"
              :readonly="isView"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <b-form-input
              v-model="item.alias"
              placeholder="请输入别名"
              :readonly="isView"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="2"
          md="2"
          class="d-flex align-items-center"
        >
          <b-button
            v-if="!isView"
            v-ripple.400="$x.ripple.danger" variant="flat-danger" size="sm" @click="removeItem(index)" class="mb-1" pill>
            <span class="align-middle">删除转换</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
    </b-form>
    <b-button
      v-if="!isView"
      v-ripple.400="$x.ripple.primary" variant="flat-primary" size="sm" @click="repeateAgain" class="bg-light-primary" pill>
      <feather-icon icon="PlusIcon" class="mr-50"/>
      <span class="align-middle">新增转换</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    properties: {
      type: Array,
      default: () => [],
    },
    isView: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
  },
  data() {
    return {
      nextTodoId: 2,
      filtersNexTodoId: 1000,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.properties.push({
        id: this.nextTodoId += this.nextTodoId,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.properties.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
</style>
