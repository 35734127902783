import request from '@/auth/jwt/useJwt'

/*  查询产品列表  */
// eslint-disable-next-line arrow-body-style
export function query(pageIndex, pageSize, params) {
  let url = `device-product/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&sorts[0].name=createTime&sorts[0].order=desc`
  let index = 0
  if (params) {
    if (params.id) {
      url += `&terms[${index}].column=id$LIKE&terms[${index}].value=%${params.id}%`
      index++
    }
    if (params.name) {
      url += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index++
    }
    if (params.state !== undefined && params.state !== null) {
      url += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
      index++
    }
    if (params.classifiedId) {
      url += `&terms[${index}].column=classifiedId&terms[${index}].value=${params.classifiedId}`
      index++
    }
    if (params.orgId) {
      url += `&terms[${index}].column=orgId&terms[${index}].value=${params.orgId}`
      index++
    }
    if (params.messageProtocol) {
      url += `&terms[${index}].column=messageProtocol&terms[${index}].value=${params.messageProtocol}`
      index++
    }
    if (params.transportProtocol) {
      url += `&terms[${index}].column=transportProtocol&terms[${index}].value=${params.transportProtocol}`
    }
  }
  //
  url = encodeURI(url)
  return request.axiosIns({
    url,
    method: 'get',
  })
}
// 查询所有产品
export function queryAllProducts() {
  return request.axiosIns({
    url: '/device-product/_query/no-paging?sorts[0].name=createTime&sorts[0].order=desc',
    method: 'get',
  })
}

/* 查询每个产品下的设备数量 */
export function count(productId) {
  let url = 'device-instance/_count'
  if (productId) {
    url += `?terms[0].column=productId&terms[0].value=${productId}`
  }
  return request.axiosIns({
    url,
    method: 'get',
  })
}
/* 查询产品品类 */
export function queryCategory() {
  return request.axiosIns({
    url: 'device/category/_query/no-paging?paging=false&sorts[0].name=id&sorts[0].order=desc',
    method: 'get',
  })
}
/* 查询所属机构 */
export function queryOrgin() {
  return request.axiosIns({
    url: 'organization/_all',
    method: 'get',
  })
}
/* 查询接入协议 */
export function queryProtocol() {
  return request.axiosIns({
    url: 'protocol/supports',
    method: 'get',
  })
}
/* 查询连接协议 */
export function protocolTransports(id) {
  return request.axiosIns({
    url: `protocol/${id}/transports`,
    method: 'get',
  })
}
/* 查询存储策略 */
export function queryPolicies() {
  return request.axiosIns({
    url: 'device/product/storage/policies',
    method: 'get',
  })
}
/* 新增产品信息  */
export function add(data) {
  return request.axiosIns({
    url: '/device/product',
    method: 'post',
    data,
  })
}
/* 编辑产品信息 */
export function edit(data) {
  return request.axiosIns({
    url: '/device-product',
    method: 'patch',
    data,
  })
}
/* 编辑产品信息 */
export function editConfig(id, data) {
  return request.axiosIns({
    url: `/device-product/${id}`,
    method: 'put',
    data,
  })
}
/*  删除产品  */
export function remove(id) {
  return request.axiosIns({
    url: `/device-product/${id}`,
    method: 'delete',
  })
}
/*  发布产品  */
export function deploy(id) {
  return request.axiosIns({
    url: `device-product/${id}/deploy`,
    method: 'post',
  })
}
/*  停用产品  */
export function unDeploy(id) {
  return request.axiosIns({
    url: `device-product/${id}/undeploy`,
    method: 'post',
  })
}
/*  查询产品信息  */
export function getById(id) {
  return request.axiosIns({
    url: `/device/product/${id}`,
    method: 'GET',
  })
}

/* 获得产品配置 */
export function getConfig(id) {
  return request.axiosIns({
    url: `device/product/${id}/config-metadata`,
    method: 'GET',
  })
}

// 获取通知类型
export function types() {
  return request.axiosIns({
    url: 'notifier/config/types',
    method: 'GET',
  })
}
// 根据通知类型查询通知配置
export function configureByType(type) {
  return request.axiosIns({
    url: `notifier/config/_query?paging=false&terms%5B0%5D.column=type&terms%5B0%5D.value=${type}`,
    method: 'get',
  })
}
