<template>
  <b-modal
    id="modalForm"
    ref="modalForm"
    :size="hasGeo ? 'lg' : 'md'"
    :visible="showForm"
    title-class="x-text-bold"
    body-class="x-scrollbar pl-4 pr-4"
    cancel-variant="flat-secondary"
    ok-title="保存"
    :title="title"
    centered
    ok-only
    @hidden="hideFormView"
    @ok="enterDialogHandle">
    <x-form-validator ref="refFormObserver" v-if="showForm">
      <x-form-feild v-for="(property, index) in propertys" :key="index"
                    :label="`${property.name} (${property.id})`" :require="isRequire">
        <b-form-radio-group
          v-if="property.valueType.type === $x.datatype.Boolean"
          v-model="formData[property.id]"
          :options="options[index].boolOptions"
          value-field="value"
          text-field="label"
        />
        <el-date-picker
          v-else-if="property.valueType.type === $x.datatype.Date"
          v-model="formData[property.id]"
          :format="options[index].dateFormat"
          :value-format="options[index].dateValueFormat"
          class="w-100"
          type="datetime"
          placeholder="选择日期时间">
        </el-date-picker>
        <v-select
          v-else-if="property.valueType.type === $x.datatype.Enum"
          v-model="formData[property.id]"
          :options="options[index].enumOptions"
          label="text"
          :reduce="option => option.value"
          :get-option-label="option => `${option.text} (${option.value})` "
          :placeholder="`请选择${property.name}`">
          <template slot="no-options">{{$t('noData')}}</template>
        </v-select>
        <b-form-textarea
          v-else-if="property.valueType.type === $x.datatype.Array || property.valueType.type === $x.datatype.Object"
          v-model="formData[property.id]"
          :placeholder="`请输入${property.name}`"
        >
        </b-form-textarea>
        <div v-else-if="property.valueType.type === $x.datatype.GeoPoint">
          <b-form-input
            v-model="formData[property.id]"
            :placeholder="`请输入${property.name}，以逗号分割`"
            class="mb-1"
          />
          <b-form-input
            v-model="options[index].address"
            placeholder="地址信息"
            disabled
            class="mb-1"
          />
          <baidu-map
            class="bm-view "
            :center="options[index].center"
            :zoom="14"
            :scroll-wheel-zoom="true"
            :map-style="mapStyle"
            @ready="mapReady(index)"
            @click="e => mapClick(e, property.id, index)"
          >
            <bm-map-type
              :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
              anchor="BMAP_ANCHOR_TOP_LEFT"
            />
            <bm-geolocation
              anchor="BMAP_ANCHOR_TOP_LEFT"
              :show-address-bar="true"
              :auto-location="true"
            />
            <bm-marker
              v-if="options[index].point"
              :icon="{url: 'http://file.cloudxin.ltd/aiot/icon/location_red.svg',opts: { imageSize: {width: 35, height: 35}}, size: {width: 35, height: 35}}"
              :position="options[index].point"
            />
          </baidu-map>
        </div>
        <cleave
          v-else-if="options[index].options"
          v-model="formData[property.id]"
          class="form-control"
          :raw="false"
          :options="options[index].options"
          :placeholder="`请输入${property.name}`"
        />
        <b-form-input
          v-else
          :type="options[index].type"
          v-model="formData[property.id]"
          :placeholder="`请输入${property.name}`"
        />
      </x-form-feild>
    </x-form-validator>
  </b-modal>
</template>

<script>
import {
  BModal, BFormRadioGroup, BFormTextarea, BFormInput,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import userAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    XFormValidator,
    XFormFeild,
    vSelect,
    Cleave,
    BModal,
    BFormRadioGroup,
    BFormTextarea,
    BFormInput,
  },
  props: {
    /**
     * 是否显示
     */
    show: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否必填
     */
    isRequire: {
      type: Boolean,
      default: true,
    },
    // 对话框标题
    title: {
      type: String,
      default: '设置属性',
    },
    // 属性数组
    propertys: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 主题
      skin: userAppConfig().skin,
      showForm: false,
      formData: {},
      options: [],
      hasGeo: false,
      ready: false,
    }
  },
  computed: {
    mapStyle() {
      return this.skin.value === 'dark' ? {
        style: 'midnight',
      } : {
        styleJson: [
          {
            featureType: 'all',
            elementType: 'geometry',
            stylers: {
              hue: '#007fff',
              saturation: 89,
            },
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: {
              color: '#ffffff',
            },
          },
        ],
      }
    },
  },
  watch: {
    show: {
      handler(obj) {
        this.showForm = obj
      },
      deep: true,
      immediate: true,
    },
    require: {
      handler() {},
      deep: true,
      immediate: true,
    },
    propertys: {
      handler(val) {
        if (!val) {
          val = []
        }
        let datas = {}
        let ops = []
        val.forEach(prop => {
          let value = this.values ? this.values[prop.id] : undefined
          const { fdata, options } = this.setFormData(prop, value)
          datas[prop.id] = fdata
          ops.push(options)
        })
        this.formData = datas
        this.options = ops
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    mapReady(index) {
      if (this.options.length < (index + 1) || !this.options[index].point) {
        return
      }
      const gc = new global.BMap.Geocoder()
      gc.getLocation(new global.BMap.Point(this.options[index].point.lng, this.options[index].point.lat), rs => {
        this.options[index].address = rs.address
        this.$forceUpdate()
      })
    },
    mapClick(e, id, index) {
      this.options[index].point = e.point
      this.formData[id] = this.options[index].point.lng + ',' + this.options[index].point.lat
      const gc = new global.BMap.Geocoder()
      gc.getLocation(this.options[index].point, rs => {
        this.options[index].address = rs.address
      })
    },
    setFormData(prop, value) {
      let fdata
      let options = {}
      if (value) {
        fdata = value
      } else {
        fdata = undefined
      }
      if (prop.valueType.type === this.$x.datatype.GeoPoint) {
        this.hasGeo = true
        if (!fdata) {
          options.center = {
            lng: 116.40355,
            lat: 39.92401,
          }
        } else {
          let point = fdata.split(',')
          if (point && point.length === 2) {
            options.center = {
              lng: point[0],
              lat: point[1],
            }
            options.point = {
              lng: point[0],
              lat: point[1],
            }
          }
        }
      }
      switch (prop.valueType.type) {
        case this.$x.datatype.Int:
        case this.$x.datatype.Long: {
          options.type = 'number'
          break
        }
        case this.$x.datatype.Float:
        case this.$x.datatype.Double: {
          if (prop.valueType.scale) {
            options.options = {
              numeral: true,
              numeralDecimalScale: prop.valueType.scale,
              delimiter: '',
            }
          }
          break
        }
        case this.$x.datatype.String: {
          if (prop.valueType.expands && prop.valueType.expands.maxLength) {
            options.options = {
              blocks: [prop.valueType.expands.maxLength],
            }
          }
          break
        }
        case this.$x.datatype.Boolean: {
          options.boolOptions = []
          let { valueType } = prop
          if (valueType.trueText && valueType.trueValue) {
            options.boolOptions.push({
              label: valueType.trueText,
              value: valueType.trueValue,
            })
          }
          if (valueType.falseText && valueType.falseValue) {
            options.boolOptions.push({
              label: valueType.falseText,
              value: valueType.falseValue,
            })
          }
          break
        }
        case this.$x.datatype.Date: {
          if (prop.valueType.format) {
            if (prop.valueType.format === this.$x.datatype.dataFormats[0].value) {
              options.dateFormat = 'yyyy-MM-dd HH:mm:ss'
              options.dateValueFormat = 'timestamp'
            } else {
              options.dateFormat = prop.valueType.format
              options.dateValueFormat = prop.valueType.format
            }
          }
          break
        }
        case this.$x.datatype.Enum: {
          if (prop.valueType.elements) {
            options.enumOptions = prop.valueType.elements
          }
          break
        }
        default: {
          options.type = 'text'
          break
        }
      }
      return {
        fdata,
        options,
      }
    },
    hide() {
      if (this.$refs.refFormObserver) {
        this.$refs.refFormObserver.reset()
      }
      this.formData = {}
      this.options = {}
      this.hasGeo = false
      this.showForm = false
      this.$emit('update:show', this.showForm)
    },
    enterDialogHandle(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.$emit('enter', this.formData, () => {
            this.hide()
          })
        }
      })
    },
    hideFormView() {
      this.hide()
      this.$emit('hide')
    },
  },
}
</script>

<style lang="scss">
  .bm-view {
    width: 100%;
    height: 20rem;
  }
</style>
