<template>
  <div>
    <x-form-repeater
      v-if="triggers"
      ref="repeater"
      :data.sync="triggers"
      no-actions
      :is-view="formType === $x.biz.FormType.VIEW"
      :item-add="add"
      class="ml-1"
      :is-first="true"
    >
      <template
        slot="form-repeater"
        slot-scope="{item, index}"
      >
        <div class="d-flex flex-column w-100 pb-50">
          <div class="d-flex flex-row align-items-center mb-50">
            <div class="x-indicator"></div>
            <span class="ml-50 mr-50">{{'触发器' + (index + 1) + '：'}}</span>
            <repeat-filter v-if="item.trigger === $x.biz.RuleEngine.Trigger.Mode.DEVICE"
                           :value="item.device.shakeLimit" :form-type="formType"
                           @enter="(formData, done) => setRepeatFilter(item, formData, done)">
            </repeat-filter>
          </div>
          <div class="d-flex w-100 justify-content-between ">
            <b-row class="w-100 d-flex align-items-center">
              <b-col md="2" class="mb-50">
                <v-select
                  v-model="item.trigger"
                  :options="$x.biz.RuleEngine.Trigger.Mode.DictData"
                  placeholder="请选择触发类型"
                  :reduce=" option => option.value"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="option => selectTrigger(triggers[index], option.value)"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="item.trigger === $x.biz.RuleEngine.Trigger.Mode.TIMER"
                md="3"
                class="d-flex align-items-center mb-50"
              >
                <b-form-input
                  v-model="item.cron"
                  :readonly="formType === $x.biz.FormType.VIEW"
                  placeholder="请输入cron表达式"
                />
                <span v-tooltip.hover="cronTip" @click="toCronGuide"
                      class="el-icon-question ml-50 text-secondary text-lighten-5 font-medium-1 x-link x-text-normal"></span>
              </b-col>
              <b-col
                v-if="item.trigger === $x.biz.RuleEngine.Trigger.Mode.SCENE"
                md="4" class="mb-50"
              >
                <v-select
                  v-model="item.scene.sceneIds"
                  :options="scenes"
                  label="name"
                  placeholder="请选择场景联动"
                  multiple
                  :reduce=" option => option.id"
                  :disabled="formType === $x.biz.FormType.VIEW"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="item.trigger === $x.biz.RuleEngine.Trigger.Mode.DEVICE"
                md="3" class="mb-50"
              >
                <b-input-group>
                  <b-form-input
                    v-model="triggers[index].device.name"
                    v-tooltip="triggers[index].device.name"
                    placeholder="请选择设备"
                    readonly
                    :disabled="formType === $x.biz.FormType.VIEW"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="showDeviceModal(index)"
                    >
                      选择
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col
                v-if="item.trigger === $x.biz.RuleEngine.Trigger.Mode.DEVICE
                && triggers[index].device && triggers[index].device.deviceId"
                md="2" class="mb-50"
              >
                <v-select
                  v-model="triggers[index].device.type"
                  :options="$x.biz.RuleEngine.Trigger.Type.DictData"
                  placeholder="请选择触发方式"
                  :reduce=" option => option.value"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="option => selectDeviceType(option, index)"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="item.trigger === $x.biz.RuleEngine.Trigger.Mode.DEVICE && triggers[index].device
                && triggers[index].device.type && triggers[index].device.type !== $x.biz.DevState.ONLINE
                && triggers[index].device.type !== $x.biz.DevState.OFFLINE"
                md="3" class="mb-50"
              >
                <v-select
                  v-model="triggers[index].device.modelId"
                  :options="triggers[index].typeValues"
                  label="name"
                  class="w-100"
                  placeholder="请选择触发项"
                  :get-option-label="option => `${option.name}（${option.id}）`"
                  :reduce="option => option.id"
                  :disabled="formType === $x.biz.FormType.VIEW"
                  @option:selected="option => selectTriggerValue(triggers[index], option.id)"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="item.trigger === $x.biz.RuleEngine.Trigger.Mode.DEVICE && triggers[index].device
                && triggers[index].device.type && triggers[index].device.type === $x.biz.RuleEngine.Trigger.Type.PROPERTIES"
                md="12" class="mb-50"
              >
                <x-form-repeater
                  :data.sync="triggers[index].device.filters"
                  :is-view="formType === $x.biz.FormType.VIEW"
                  class="mt-50"
                  add-text="新增过滤条件"
                  no-actions
                  :is-first="true"
                >
                  <template
                    slot="form-repeater"
                    slot-scope="scope"
                  >
                    <b-row v-if="triggers[index].device.filters[scope.index]" class="w-100">
                      <b-col
                        md="2"
                      >

                        <b-form-input
                          v-model="triggers[index].device.filters[scope.index].key"
                          placeholder="请选择过滤条件KEY"
                          readonly
                          disabled
                        />
<!--                        <v-select-->
<!--                          v-model="triggers[index].device.filters[scope.index].key"-->
<!--                          :options="[{ id: triggers[index].device.modelId, name: triggers[index].typeValue ? triggers[index].typeValue.name : undefined}]"-->
<!--                          label="name"-->
<!--                          placeholder="请选择过滤条件KEY"-->
<!--                          :reduce=" option => option.id"-->
<!--                          :get-option-label="option => `${option.name}（${option.id}）`"-->
<!--                          disabled-->
<!--                        >-->
<!--                          <template slot="no-options">-->
<!--                            {{ $t('noData') }}-->
<!--                          </template>-->
<!--                        </v-select>-->
                      </b-col>
                      <b-col
                        md="3"
                      >
                        <v-select
                          v-model="triggers[index].device.filters[scope.index].operator"
                          :options="$x.biz.RuleEngine.Operators"
                          label="label"
                          placeholder="请选择操作符"
                          :reduce=" option => option.value"
                          :disabled="formType === $x.biz.FormType.VIEW"
                        >
                          <template slot="no-options">
                            {{ $t('noData') }}
                          </template>
                        </v-select>
                      </b-col>
                      <b-col
                        md="3"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="triggers[index].device.filters[scope.index].value"
                            placeholder="请输入过滤条件值"
                            readonly
                            :disabled="formType === $x.biz.FormType.VIEW"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-primary"
                              @click="showFilterValueModal(index, scope.index)"
                            >
                              输入
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                      <b-col
                        md="3"
                      >
                        <b-button
                          v-if="formType !== $x.biz.FormType.VIEW"
                          v-ripple.400="$x.ripple.danger"
                          variant="flat-danger"
                          size="sm"
                          class="btn-light-danger"
                          pill
                          @click="removeItemFilters(index, scope.index)"
                        >
                          <span class="align-middle">删除</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </template>
                </x-form-repeater>
              </b-col>
            </b-row>
            <b-col md="2">
              <b-button
                v-if="formType !== $x.biz.FormType.VIEW"
                v-ripple.400="$x.ripple.danger"
                variant="flat-danger"
                size="sm"
                class="btn-light-danger"
                pill
                @click="removeItem(index)"
              >
                <span class="align-middle">删除触发器</span>
              </b-button>
            </b-col>
          </div>
<!--          <b-row><b-col><hr></b-col></b-row>-->
        </div>
      </template>
    </x-form-repeater>
    <b-modal
      title-class="x-text-bold"
      body-class="pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      size="xl"
      title="选择设备"
      :visible.sync="modalFlag"
      @hidden="modalFlag = false"
      @ok="handleOk"
    >
      <x-table
        ref="deviceTable"
        :card="false"
        :options="options"
        :fixed="false"
        :selected-value="selectDevice ? [selectDevice] : []"
        @search="searchHandle"
        @rowSelected="rowSelected"
      />
    </b-modal>
    <property-value-form
      v-if="showFilterValue"
      :show.sync="showFilterValue"
      :propertys="[triggers[editTriggerIndex].typeValue]"
      :values="editFilterValue"
      :is-require="false"
      title="编辑"
      @enter="editFilterValueDone">
    </property-value-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BInputGroup, BFormInput, BInputGroupAppend, BModal,
} from 'bootstrap-vue'
import XFormRepeater from '@/@core/components/cx/table/XFormRepeater.vue'
import XTable from '@/@core/components/cx/table/XTable.vue'
import PropertyValueForm from '@/views/device-center/device-manager/things-model/PropertyValueForm.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { query, getById } from '@/api/device-manage/device-list'
import { scenesNoPaging } from '@/api/data-center/scene-linkage'
import RepeatFilter from './RepeatFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    XFormRepeater,
    XTable,
    vSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BModal,
    PropertyValueForm,
    RepeatFilter,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    formType: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      showFilterValue: false,
      editTriggerIndex: undefined,
      editFilterIndex: undefined,
      editFilterValue: undefined,
      triggers: this.value,
      modalFlag: false,
      // 选择的输出设备
      editIndex: undefined,
      selectDevice: undefined,
      // 场景联动列表
      scenes: [],
      cronTip: 'Cron表达式填写方式：\n您可以参考详细表达式！',
      options: {
        addBtn: false,
        exportBtn: false,
        printBtn: false,
        hideStyleSwitch: true,
        actions: [],
        rowSelectable: true,
        rowSingleSelectable: true,
        columns: [{
          label: '设备ID',
          prop: 'id',
          addShow: false,
          editShow: false,
          searchShow: true,
        }, {
          label: '设备名称',
          prop: 'name',
          addShow: false,
          editShow: false,
          searchShow: true,
        }, {
          label: '所属产品',
          prop: 'productId',
          linkUrl: '/device-center/products/view/{productId}',
          type: 'select',
          dictUrl: 'device-product/_query/no-paging?paging=false',
          props: { label: 'name', value: 'id' },
          getDictLabel: option => option.label + `（${option.value})`,
          searchShow: true,
        }, {
          label: '状态',
          prop: 'state',
          props: {
            label: 'text',
            value: 'value',
          },
          type: 'select',
          dictData: this.$x.biz.DevState.DictData,
          rowSelectDot: true,
          selectVariant: prop => {
            if (prop.value === 'notActive') {
              return 'text-warning'
            }
            if (prop.value === 'online') {
              return 'text-success'
            }
            return 'text-danger'
          },
          addShow: false,
          editShow: false,
          searchShow: true,
        }, {
          label: '创建时间',
          labelShow: true,
          prop: 'createTime',
          type: 'datetimerange',
          addShow: false,
          editShow: false,
          searchShow: true,
        }],
      },
    }
  },
  created() {
    if (this.triggers.length > 0) {
      this.triggers.forEach((trigger, index) => {
        if (trigger.trigger) {
          // 初始化数据
          this.add(trigger)
        }
        if (trigger.trigger === this.$x.biz.RuleEngine.Trigger.Mode.DEVICE) {
          getById(trigger.device.deviceId).then(resp => {
            const deviceProfile = resp.data.result
            if (!deviceProfile) {
              return
            }
            this.selectDevice = deviceProfile
            this.editIndex = index
            this.selectDeviceDone(() => {
              this.selectDeviceType({ value: trigger.device.type }, index, true)
              this.selectTriggerValue(trigger, this.triggers[index].device.modelId, true)
            }, true)
          })
        } else if (trigger.trigger === this.$x.biz.RuleEngine.Trigger.Mode.SCENE) {
          scenesNoPaging().then(resp => {
            this.scenes = resp.data.result
          })
        }
      })
    }
  },
  methods: {
    searchHandle(page, params, done) {
      if (!params) {
        params = {}
      }
      query(page.pageIndex - 1, page.pageSize, params, params.productId).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    toCronGuide() {
      window.open('http://crontab.org/?spm=5176.11485173.0.0.61fc59afkRYQHs', '_blank')
    },
    rowSelected(rows) {
      if (rows && rows.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.selectDevice = rows[0]
      } else {
        this.selectDevice = undefined
      }
    },
    selectDeviceDone(done, keep) {
      if (this.selectDevice) {
        getById(this.selectDevice.id).then(resp => {
          const deviceProfile = resp.data.result
          this.triggers[this.editIndex].device = {
            modelId: keep ? this.triggers[this.editIndex].device.modelId : undefined,
            type: keep ? this.triggers[this.editIndex].device.type : undefined,
            shakeLimit: keep ? this.triggers[this.editIndex].device.shakeLimit : undefined,
            productId: deviceProfile.productId,
            deviceId: deviceProfile.id,
            name: deviceProfile.name,
            filters: keep ? this.triggers[this.editIndex].device.filters : [],
            metadata: deviceProfile.metadata ? JSON.parse(deviceProfile.metadata) : {},
          }
          this.modalFlag = false
          this.editIndex = undefined
          if (done) {
            done()
          }
          this.$refs.repeater.$forceUpdate()
        })
      }
    },
    handleOk() {
      this.selectDeviceDone()
    },
    add(data) {
      if (data) {
        if (!data.device) {
          data.device = {
            modelId: undefined,
            filters: [],
            shakeLimit: { enabled: false },
          }
        }
        if (!data.device.modelId) {
          data.device.modelId = undefined
        }
        if (!data.device.filters) {
          data.device.filters = []
        }
        if (!data.device.shakeLimit) {
          data.device.shakeLimit = { enabled: false }
        }
        if (!data.scene) {
          data.scene = {
            sceneIds: undefined,
          }
        }
        if (!data.scene.sceneIds) {
          data.scene.sceneIds = undefined
        }
        if (!data.typeValues) {
          data.typeValues = []
        }
        return data
      }
      // eslint-disable-next-line no-return-assign
      return {
        device: {
          modelId: undefined,
          filters: [],
          shakeLimit: { enabled: false },
        },
        scene: {
          sceneIds: undefined,
        },
        typeValues: [],
        trigger: undefined,
      }
    },
    setRepeatFilter(item, formData, done) {
      if (!item.device.shakeLimit) {
        item.device.shakeLimit = {}
      }
      item.device.shakeLimit.enabled = formData.enabled
      if (item.device.shakeLimit.enabled) {
        item.device.shakeLimit.alarmFirst = formData.alarmFirst
        item.device.shakeLimit.time = formData.time
        item.device.shakeLimit.threshold = 1
      }
      this.$refs.repeater.$forceUpdate()
      done()
    },
    removeItem(index) {
      if (this.$refs.repeater) {
        this.$refs.repeater.removeItem(index)
      }
    },
    removeItemFilters(triggerIndex, index) {
      this.triggers[triggerIndex].device.filters.splice(index, 1)
    },
    // 加载设备列表选择
    showDeviceModal(index) {
      if (this.formType !== this.$x.biz.FormType.VIEW) {
        this.editIndex = index
        this.modalFlag = true
        this.selectDevice = this.triggers[index].device
      }
    },
    clickChange(item) {
      this.tableRadio = item.id
    },
    // 选择触发类型
    selectDeviceType(option, index, keep) {
      if (!keep) {
        this.triggers[index].device.modelId = undefined
        this.triggers[index].device.filters = []
      }
      this.triggers[index].typeValues = []
      if (option.value === this.$x.biz.RuleEngine.Trigger.Type.PROPERTIES) {
        this.triggers[index].typeValues = this.triggers[index].device.metadata.properties
      } else if (option.value === this.$x.biz.RuleEngine.Trigger.Type.EVENT) {
        this.triggers[index].typeValues = this.triggers[index].device.metadata.events
      } else if (option.value === this.$x.biz.RuleEngine.Trigger.Type.FUNCTION) {
        this.triggers[index].typeValues = this.triggers[index].device.metadata.functions
      }
      this.$refs.repeater.$forceUpdate()
    },
    // 选择触发方式
    selectTrigger(trigger, value) {
      // this.triggers[index].cron = undefined
      switch (value) {
        case this.$x.biz.RuleEngine.Trigger.Mode.DEVICE: {
          break
        }
        case this.$x.biz.RuleEngine.Trigger.Mode.SCENE: {
          scenesNoPaging().then(resp => {
            this.scenes = resp.data.result
          })
          break
        }
        default:
          break
      }
    },
    // 选择参数
    selectTriggerValue(trigger, value, keep) {
      // this.triggers[index].cron = undefined
      if (!keep) {
        trigger.device.filters = [{
          key: value,
        }]
      }
      for (let val of trigger.typeValues) {
        if (val.id === value) {
          trigger.typeValue = val
        }
      }
      this.$refs.repeater.$forceUpdate()
    },
    addFilter(trigger, value) {
      for (let val of trigger.typeValues) {
        if (val.id === value) {
          trigger.typeValue = val
        }
      }
      return {
        key: value,
      }
    },
    showFilterValueModal(triggerIndex, filterIndex) {
      this.editTriggerIndex = triggerIndex
      this.editFilterIndex = filterIndex
      this.editFilterValue = {}
      this.editFilterValue[this.triggers[triggerIndex].device.filters[filterIndex].key] = this.triggers[triggerIndex].device.filters[filterIndex].value
        ? this.triggers[triggerIndex].device.filters[filterIndex].value : undefined
      this.showFilterValue = true
    },
    editFilterValueDone(formData, done) {
      this.$nextTick(() => {
        this.triggers[this.editTriggerIndex].device.filters[this.editFilterIndex].value = formData[this.triggers[this.editTriggerIndex].device.filters[this.editFilterIndex].key]
        this.$refs.repeater.$forceUpdate()
      })
      done()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
