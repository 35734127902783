<template>
  <div>
    <x-form-validator ref="refFormObserver">
      <b-row class="w-100">
        <b-col
          md="12"
          class="ml-1"
        >
          <el-collapse value="1" accordion>
            <el-collapse-item name="1">
              <template slot="title">
                <span class="x-text-bold font-medium-1">描述信息：</span>
              </template>
              <x-form-feild
                label="告警ID"
                require
              >
                <b-form-input
                  v-model="formData.id"
                  :readonly="formType !== $x.biz.FormType.ADD"
                  placeholder="请输入告警ID"
                />
              </x-form-feild>
              <x-form-feild
                label="告警名称"
                require
              >
                <b-form-input
                  v-model="formData.name"
                  :readonly="formType === $x.biz.FormType.VIEW"
                  placeholder="请输入告警名称"
                />
              </x-form-feild>
              <x-form-feild
                label="描述"
              >
                <b-form-textarea
                  v-model="formData.description"
                  :readonly="formType === $x.biz.FormType.VIEW"
                  placeholder="请输入告警描述"
                />
              </x-form-feild>
            </el-collapse-item>
          </el-collapse>
        </b-col>
      </b-row>
<!--      <x-form-feild label="防抖">-->
<!--        <b-form-checkbox-->
<!--          v-model="formData.alarmRule.shakeLimit.enabled"-->
<!--          checked="false"-->
<!--          class="custom-control-primary mb-1"-->
<!--          name="check-button"-->
<!--          switch-->
<!--          size="35"-->
<!--        >-->
<!--          <span class="switch-icon-left">-->
<!--            <feather-icon icon="CheckIcon" />-->
<!--          </span>-->
<!--          <span class="switch-icon-right">-->
<!--            <feather-icon icon="XIcon" />-->
<!--          </span>-->
<!--        </b-form-checkbox>-->
<!--      </x-form-feild>-->
<!--      <div-->
<!--        v-if="formData.alarmRule.shakeLimit.enabled"-->
<!--        class="pl-1 mb-1"-->
<!--      >-->
<!--        若-->
<!--        <b-form-input-->
<!--          v-model="formData.alarmRule.shakeLimit.time"-->
<!--          type="number"-->
<!--          class="ml-1 mr-1"-->
<!--          style="width: 100px; display: inline;"-->
<!--          placeholder="秒数"-->
<!--        />-->
<!--        内发生-->
<!--        <b-form-input-->
<!--          v-model="formData.alarmRule.shakeLimit.threshold"-->
<!--          type="number"-->
<!--          class="ml-1 mr-1"-->
<!--          style="width: 100px; display: inline;"-->
<!--          placeholder="点击次数"-->
<!--        />-->
<!--        次及以上时，处理-->
<!--        <b-form-radio-->
<!--          v-model="formData.alarmRule.shakeLimit.alarmFirst"-->
<!--          name="some-radio9"-->
<!--          value="true"-->
<!--          class="custom-control-primary"-->
<!--          style="display: inline-block !important;"-->
<!--        >-->
<!--          第一次-->
<!--        </b-form-radio>-->
<!--        <b-form-radio-->
<!--          v-model="formData.alarmRule.shakeLimit.alarmFirst"-->
<!--          name="some-radio9"-->
<!--          value="false"-->
<!--          class="custom-control-primary ml-1"-->
<!--          style="display: inline-block !important;"-->
<!--        >-->
<!--          最后一次-->
<!--        </b-form-radio>-->
<!--      </div>-->
      <x-form-feild
        label="触发器"
        tip="触发条件满足条件中任意一个即可触发"
      >
        <triggers-form
          v-if="paramObj"
          :form-type="formType"
          :value="formData.alarmRule.triggers"
          :repeat-filter="formData.alarmRule.shakeLimit"
          :obj="paramObj"
        />
      </x-form-feild>
      <x-form-feild
        v-if="false"
        label="转换"
        tip="将触发告警原始数据字段转换为自定义字段，例如：productId 转为 pid"
      >
        <alarm-properties
          :form-type="formType"
          :properties="formData.alarmRule.properties"
        />
      </x-form-feild>
      <x-form-feild
        label="执行动作"
        tip="满足条件后需要执行的动作"
        class="mb-3"
      >
        <execute-action-form
          :form-type="formType"
          :value="formData.alarmRule.actions"
        />
      </x-form-feild>
    </x-form-validator>
    <div class="fixed-bottom d-flex">
      <b-button
        v-if="formType !== $x.biz.FormType.VIEW"
        class="flex-grow-1 ml-2 mr-2 mb-2"
        variant="primary"
        type="submit"
        @click.prevent="validationForm"
      >
        确定
      </b-button>
    </div>
  </div>
</template>

<script>
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  getById as getProductById,
} from '@/api/device-manage/product-list'
import {
  getById as getDeviceById,
} from '@/api/device-manage/device-list'
import {
  patchAlarmConfig,
} from '@/api/device-manage/alarm-manage'
import TriggersForm from './AlarmTriggers.vue'
import AlarmProperties from './AlarmProperties.vue'
import ExecuteActionForm from '../../../data-center/rule-engine/components/ExecuteActionForm.vue'

export default {
  components: {
    BRow,
    BCol,
    XFormValidator,
    BFormInput,
    BButton,
    BFormTextarea,
    TriggersForm,
    ExecuteActionForm,
    XFormFeild,
    AlarmProperties,
  },
  props: {
    /**
     * 是否为产品物模型
     */
    isProduct: {
      type: Boolean,
      default: true,
    },
    /**
     * 设备或产品ID
     */
    objId: {
      type: String,
      default: undefined,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    formType: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      paramObj: undefined,
    }
  },
  created() {
    if (!this.formData.alarmRule.shakeLimit) {
      this.formData.alarmRule.shakeLimit = {
        enable: false,
      }
    }
    if (this.isProduct) {
      // 获取产品信息
      getProductById(this.objId).then(resp => {
        if (resp.data.result.metadata) {
          resp.data.result.metadata = JSON.parse(resp.data.result.metadata)
        }
        this.paramObj = resp.data.result
        this.formData.target = 'product'
        this.formData.targetId = this.paramObj.id
        this.formData.alarmRule.name = this.paramObj.name
        this.formData.alarmRule.productId = this.paramObj.id
        this.formData.alarmRule.productName = this.paramObj.name
      })
    } else if (!this.isProduct) {
      // 获取设备信息
      getDeviceById(this.objId).then(resp => {
        if (resp.data.result.metadata) {
          resp.data.result.metadata = JSON.parse(resp.data.result.metadata)
        }
        this.paramObj = resp.data.result
        this.formData.target = 'device'
        this.formData.targetId = this.paramObj.id
        this.formData.alarmRule.name = this.paramObj.name
        this.formData.alarmRule.deviceId = this.paramObj.id
        this.formData.alarmRule.deviceName = this.paramObj.name
        this.formData.alarmRule.productId = this.paramObj.productId
        this.formData.alarmRule.productName = this.paramObj.productName
      })
    }
  },
  methods: {
    validationForm() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          patchAlarmConfig(this.formData).then(() => {
            this.$xtoast.success('编辑成功！')
            this.$emit('editSuccess')
          })
        }
      })
    },
  },
}
</script>
